import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Grid, Typography, makeStyles } from '@material-ui/core';
import { grey, common } from '@material-ui/core/colors';

const useStyles = makeStyles({
    header: {
        borderBottom: `solid 1px ${grey[300]}`,
        backgroundColor: common.white,
        boxShadow: 'none',
    },
    title: {
        padding: '1rem',
        '& h1': {
            fontSize: '2.125rem',
            textAlign: 'right',
            color: grey[800],
        },
    },
    logo: {
        padding: 8,
        '& img': {
            height: 50,
        },
    },
});

interface GenericHeaderProps {
    title: string;
}

export function GenericHeader(props: GenericHeaderProps) {
    const classes = useStyles();
    return (
        <AppBar className={classes.header} id="header">
            <Grid container alignContent="center" alignItems="center">
                <Grid item xs={6} className={classes.logo}>
                    <Link to="/">
                        <img src="/content/punters-hq.svg" alt="Punters HQ logo" />
                    </Link>
                </Grid>
                <Grid item xs={6} className={classes.title}>
                    <Typography variant="h1">{props.title}</Typography>
                </Grid>
            </Grid>
        </AppBar>
    );
}
