import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const useStyles = makeStyles({
    codes: {
        '& img.afl': {
            height: 22,
        },
        '& img.nrl': {
            height: 30,
        },
    },
    button: {
        width: 55,
    },
});

interface CodesProps {
    selected: { [code: string]: boolean };
    onClick?: (code: string) => void;
    disabled?: boolean;
}

export function Codes(props: CodesProps) {
    const classes = useStyles();
    return (
        <ToggleButtonGroup size="small" exclusive className={classes.codes}>
            <ToggleButton
                className={classes.button}
                selected={props.selected['nrl']}
                value="nrl"
                onClick={() => props.onClick && props.onClick('nrl')}
                disabled={props.disabled}
            >
                <img src={`/content/nrl.svg`} className="nrl" alt="NRL logo" />
            </ToggleButton>
            <ToggleButton
                className={classes.button}
                selected={props.selected['afl']}
                value="afl"
                onClick={() => props.onClick && props.onClick('afl')}
                disabled={props.disabled}
            >
                <img src={`/content/afl.svg`} className="afl" alt="AFL logo" />
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
