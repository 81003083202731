import React from 'react';
import moment from 'moment';
import { BetRecommendation, BetType, Match } from '@punters-hq/common';
import { chain, round } from 'lodash';
import { Link } from 'react-router-dom';
import { List, ListSubheader, ListItem, Typography } from '@material-ui/core';

import { reduceBetRecommendations } from '../store/selectors';
import { FirebasePath, AccessDenied } from '.';
import { currentRoundPath, betRecommendationsPath } from '../utils';
import { OfflineBolt, ChevronRight } from '@material-ui/icons';
import styles from './Recommendations.module.css';
import { makeStyles } from '@material-ui/styles';
import { green, grey } from '@material-ui/core/colors';
import { LoadingTableRow } from './Loading';
import { FirebaseObject } from './FirebaseObject';

const BetTypeText = {
    [BetType.HomeTeam]: (
        <span>
            <b>H2H:</b> Back HOME team
        </span>
    ),
    [BetType.AwayTeam]: (
        <span>
            <b>H2H:</b> Back AWAY team
        </span>
    ),
    [BetType.HomeLine]: (
        <span>
            <b>Line:</b> Back HOME line
        </span>
    ),
    [BetType.AwayLine]: (
        <span>
            <b>Line:</b> Back AWAY line
        </span>
    ),
    [BetType.OverScore]: (
        <span>
            <b>Totals:</b> Back OVER total points
        </span>
    ),
    [BetType.UnderScore]: (
        <span>
            <b>Totals:</b> Back UNDER total points
        </span>
    ),
};

export const BetTypeToMarket = {
    [BetType.HomeTeam]: 'H2H - Home',
    [BetType.AwayTeam]: 'H2H - Away',
    [BetType.HomeLine]: 'LINE - Home',
    [BetType.AwayLine]: 'LINE - Away',
    [BetType.OverScore]: 'TOTAL - Over',
    [BetType.UnderScore]: 'TOTAL - Under',
};

const useStyles = makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        textTransform: 'uppercase',
        lineHeight: '35px',
        '& svg': {
            margin: 'auto 0',
            color: green[500],
            opacity: 0.5,
        },
        '& span': {
            fontSize: '0.8rem',
        },
    },
    recommendations: {
        borderBottom: `solid 1px ${grey[300]}`,
    },
    recommendation: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        textDecoration: 'none',
        color: grey[800],
        margin: 'auto 0',
        '& p': {
            fontSize: '0.9rem',
        },
    },
});

interface RecommendationsProps {
    onClick?: () => void;
    code: string;
    round: string;
    match: Match;
}

const NoRecommendations = () => {
    return (
        <ListItem>
            <Typography variant="caption">No bet recommendations</Typography>
        </ListItem>
    );
};

export function Recommendations(props: RecommendationsProps) {
    const classes = useStyles();
    const onClick = props.onClick ? () => props.onClick!() : undefined;
    return (
        <List className={classes.recommendations}>
            <ListSubheader className={classes.header}>
                <span>Bet Recommendations</span>
                <OfflineBolt fontSize="small" />
            </ListSubheader>
            <FirebaseObject
                path={betRecommendationsPath({ code: props.code, round: props.round })}
                defaultValue={[]}
                error={() => <AccessDenied />}
            >
                {(recommendations: BetRecommendation[]) => {
                    if (!recommendations) {
                        return <NoRecommendations />;
                    }

                    const key = `${props.match.homeTeam}-${props.match.awayTeam}-${moment(props.match.date).format(
                        'YYYYMMDD',
                    )}`;
                    const mapped = recommendations
                        .filter(x => x.matchKey === key)
                        .map((x, index) => {
                            return (
                                <ListItem key={`${x.matchKey}-${x.strategy}-${index}`} onClick={onClick}>
                                    <Link className={classes.recommendation} to={`/${props.code}/strategy/2019`}>
                                        <Typography variant="body1">
                                            {BetTypeText[x.betType!]}
                                            &nbsp; ${round(x.resetCapitalBetAmount, 0).toFixed(2)} @ $
                                            {(x.betReturn + 1).toFixed(2)}
                                        </Typography>
                                        <ChevronRight fontSize="small" />
                                    </Link>
                                </ListItem>
                            );
                        });

                    return mapped.length === 0 ? <NoRecommendations /> : mapped;
                }}
            </FirebaseObject>
        </List>
    );
}

interface RecommendationRowsProps {
    code: string;
}

export function RecommendationRows(props: RecommendationRowsProps) {
    return (
        <FirebasePath path={currentRoundPath(props)} loading={() => <LoadingTableRow />}>
            {(currentRound: string) => {
                return (
                    <FirebaseObject
                        path={betRecommendationsPath({ ...props, round: currentRound })}
                        defaultValue={[]}
                        loading={() => <LoadingTableRow />}
                    >
                        {(value: BetRecommendation[]) => {
                            const rows = reduceBetRecommendations(value);
                            const flattened: BetRecommendation[] = chain(rows)
                                .values()
                                .flatten()
                                .value();
                            return (
                                <>
                                    {flattened.map((x: BetRecommendation) => {
                                        const [homeTeam, awayTeam, date] = x.matchKey.split('-');
                                        return (
                                            <tr className={styles.pending} key={`${x.matchKey}-${x.strategy}`}>
                                                <td>{moment(date).format('DD MMM YYYY')}</td>
                                                <td>
                                                    {homeTeam} vs. {awayTeam}
                                                </td>
                                                <td>{BetTypeToMarket[x.betType]}</td>
                                                <td>${(x.betReturn + 1).toFixed(2)}</td>
                                                <td>PENDING</td>
                                                <td>${round(x.resetCapitalBetAmount).toFixed(2)}</td>
                                                <td>N/A</td>
                                                <td>N/A</td>
                                            </tr>
                                        );
                                    })}
                                </>
                            );
                        }}
                    </FirebaseObject>
                );
            }}
        </FirebasePath>
    );
}
