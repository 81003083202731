import React from 'react';
import cx from 'classnames';
import { makeStyles, CircularProgress } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { common } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import { AppState } from '../store';

interface ProgressButtonProps extends ButtonProps {}

const useStyles = makeStyles({
    progress: {
        margin: 'auto',
        marginLeft: 10,
    },
    button: {
        backgroundColor: common.white,
    },
    wrapper: {
        display: 'flex',
    },
});

export function ProgressButton(props: ProgressButtonProps) {
    const classes = useStyles();
    const loading = useSelector<AppState, boolean>(state => state.data.loading);

    const { onClick, className, disabled, ...rest } = props;

    return (
        <Button disabled={disabled || loading} className={cx(className, classes.button)} onClick={onClick} {...rest}>
            <div className={classes.wrapper}>
                {props.children}
                {loading && <CircularProgress size={14} className={classes.progress} color="secondary" />}
            </div>
        </Button>
    );
}
