import React from 'react';
import moment from 'moment';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { push } from 'connected-react-router';
import { grey } from '@material-ui/core/colors';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, makeStyles, List, ListItem, ListItemText, Button } from '@material-ui/core';
import { get } from 'lodash';

import { SubscriptionInfo, ProductType } from '@punters-hq/common';
import { ProductButton } from './ProductButton';
import { Codes } from './Codes';
import { Loading } from './Loading';
import { AppState } from '../store';
import { getAuthToken } from '../firebase';
import { CancelSubscriptionButton } from './CancelSubscriptionButton';
import { client } from '../client';

const useStyles = makeStyles({
    header: {
        fontSize: '1rem',
        textTransform: 'uppercase',
        color: grey[700],
        marginBottom: '0.5rem',
        textAlign: 'center',
    },
    item: {
        padding: 0,
    },
    center: {
        textAlign: 'center',
    },
    product: {
        textAlign: 'center',
        border: `solid 1px ${grey[300]}`,
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
    },
    row: {
        marginBottom: '1rem',
    },
});

type SubscriptionResponse = {
    subscriptionInfo: SubscriptionInfo;
    stripeInfo: {
        trialStart: number;
        trialEnd: number;
        nextPaymentDate: number;
        status: string;
        promoCode: string | null;
    };
};

export function SubscriptionDetails() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const user = useSelector<AppState, firebase.User>(state => state.data.user!);
    const { data, isLoading, error } = useQuery<AxiosResponse<SubscriptionResponse>, any>('subscription', async () =>
        client.get('/subscription', {
            headers: {
                Authorization: await getAuthToken(user),
            },
        }),
    );

    if (isLoading) {
        return <Loading />;
    }

    const onClick = () => {
        const productType = get(data, 'data.subscriptionInfo.product.type', ProductType.BothCodesMonthly);
        dispatch(push(`/sign-up/${productType.toLowerCase()}/subscription`));
    };

    const showCancelButton = data && !data.data.subscriptionInfo.isCancelled;
    const showCreateSubscriptioButton = error || (data && data.data.subscriptionInfo.isCancelled);

    return (
        <>
            <Grid container className={classes.row}>
                <Grid item xs={12}>
                    <Typography className={classes.header} variant="h4">
                        Subscription Details
                    </Typography>
                </Grid>
            </Grid>
            {data && (
                <>
                    <Grid container className={classes.row}>
                        <Grid item xs={6} className={classes.center}>
                            <Codes
                                selected={data.data.subscriptionInfo.codes}
                                onClick={code => dispatch(push(`/${code}`))}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.center}>
                            <ProductButton
                                className={classes.product}
                                product={data.data.subscriptionInfo.product}
                                selected={false}
                                onSelectProduct={type => true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <List className={classes.list}>
                                <ListItem className={classes.item}>
                                    <ListItemText
                                        primary={data.data.stripeInfo.status.toUpperCase()}
                                        secondary="Status"
                                    />
                                </ListItem>
                                <ListItem className={classes.item}>
                                    <ListItemText
                                        primary={moment.unix(data.data.stripeInfo.trialStart).format('DD MMM YYYY')}
                                        secondary="Trial start"
                                    />
                                </ListItem>
                                <ListItem className={classes.item}>
                                    <ListItemText
                                        primary={moment.unix(data.data.stripeInfo.trialEnd).format('DD MMM YYYY')}
                                        secondary="Trial end"
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <List className={classes.list}>
                                <ListItem className={classes.item}>
                                    <ListItemText
                                        primary={moment
                                            .unix(data.data.stripeInfo.nextPaymentDate)
                                            .format('DD MMM YYYY')}
                                        secondary="Next payment"
                                    />
                                </ListItem>
                                {data.data.stripeInfo.promoCode && (
                                    <>
                                        <ListItem className={classes.item}>
                                            <ListItemText
                                                primary={data.data.stripeInfo.promoCode}
                                                secondary="Promo code"
                                            />
                                        </ListItem>
                                        <ListItem className={classes.item}></ListItem>
                                    </>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                </>
            )}
            <Grid container>
                <Grid item xs={12}>
                    {showCancelButton && <CancelSubscriptionButton user={user} />}
                    {showCreateSubscriptioButton && (
                        <Button onClick={onClick} variant="outlined" color="secondary" fullWidth>
                            Create Subscription
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    );
}
