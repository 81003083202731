import React, { useEffect } from 'react';
import moment from 'moment';
import { chain } from 'lodash';
import * as Model from '@punters-hq/common';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { hasBeenPlayed, roundPath } from '../utils';
import { FirebasePath, MatchRow, SubHeader } from '../components';
import { storeSelectedRound } from '../store';

interface RoundProps {
    code: string;
    year: string;
    round: string;
}

export const Round = (props: RoundProps) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(storeSelectedRound(props.code, props.round));
    }, [dispatch, props.code, props.round]);

    return (
        <FirebasePath path={roundPath(props)}>
            {(round: Model.Round) => {
                return (
                    <>
                        <SubHeader
                            header={`Round ${round.round}`}
                            navLeftPath={`/${props.code}/${props.year}/round/${Number(round.roundNo) - 1}`}
                            navRightPath={`/${props.code}/${props.year}/round/${Number(round.roundNo) + 1}`}
                        />
                        <RoundTable code={props.code} year={props.year} round={round} />
                    </>
                );
            }}
        </FirebasePath>
    );
};

interface RoundTableProps {
    code: string;
    year: string;
    round: Model.Round;
    recommendations?: Model.BetRecommendations;
}

export const RoundTable = (props: RoundTableProps) => {
    const dispatch = useDispatch();
    const onClick = (path: string) => dispatch(push(path));
    const matches = chain(props.round.matches)
        .values()
        .sortBy(x => moment(x.date))
        .map((match: Model.Match, index: number, collection: Model.Match[]) => {
            const date = moment(match.date).format('ddd DD MMM h:mm A');
            const key = `${match.homeTeam}-${match.awayTeam}`;
            const onRowClick = () => onClick(`/${props.code}/${props.year}/match/${match.roundNo}/${match.key}/odds`);
            const played = hasBeenPlayed(match);
            const shouldScroll = !played && (index === 0 || hasBeenPlayed(collection[index - 1]));

            return (
                <MatchRow
                    key={key}
                    code={props.code}
                    shouldScroll={shouldScroll}
                    played={played}
                    match={match}
                    onRowClick={onRowClick}
                    date={date}
                    round={props.round.round}
                />
            );
        })
        .value();
    return <div>{matches}</div>;
};
