import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Grid, makeStyles, Tabs, Tab, Typography } from '@material-ui/core';
import { Match } from '@punters-hq/common';
import { MatchScore } from './MatchScore';
import { TeamForm } from './TeamForm';
import { TeamLogoWithPosition } from './TeamLogo';
import { hasBeenPlayed, matchPath } from '../utils';
import { FirebaseObject } from './FirebaseObject';

interface MatchHeaderProps {
    code: string;
    year: string;
    round: string;
    matchKey: string;
    currentPath: string;
}

const useStyles = makeStyles({
    container: {
        marginTop: 10,
    },
    details: {
        marginTop: 10,
        marginBottom: 10,
    },
    score: {
        marginTop: 10,
        marginBottom: 10,
    },
    center: {
        textAlign: 'center',
    },
    tabs: {
        borderBottom: 'solid 1px #ddd',
    },
});

export const MatchHeader = (props: MatchHeaderProps) => {
    const classes = useStyles();
    return (
        <FirebaseObject path={matchPath(props)}>
            {(match: Match) => {
                const path = `/${props.code}/${props.year}/match/${match.roundNo}/${match.key}`;
                const played = hasBeenPlayed(match);

                const routes = {
                    odds: `${path}/odds`,
                    atHome: path,
                    combined: `${path}/combined`,
                    stats: `${path}/stats`,
                };

                const value = props.currentPath.endsWith('odds')
                    ? routes.odds
                    : props.currentPath.endsWith('combined')
                    ? routes.combined
                    : props.currentPath.endsWith('stats')
                    ? routes.stats
                    : routes.atHome;

                return (
                    <div className={classes.container}>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item xs={5} className={classes.center}>
                                <TeamLogoWithPosition placement="left" code={props.code} team={match.homeTeam} />
                            </Grid>
                            <Grid item xs={2} className={classes.center}>
                                <Typography variant="h5">vs</Typography>
                            </Grid>
                            <Grid item xs={5} className={classes.center}>
                                <TeamLogoWithPosition placement="right" code={props.code} team={match.awayTeam} />
                            </Grid>
                        </Grid>
                        {played && <MatchScore className={classes.score} code={props.code} match={match} />}
                        <Grid container>
                            <Grid item xs={5}>
                                <TeamForm team={match.homeTeam} year={props.year} code={props.code} pastGames={5} />
                            </Grid>
                            <Grid item xs={2}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={5}>
                                <TeamForm team={match.awayTeam} year={props.year} code={props.code} pastGames={5} />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.details}>
                            <Grid item xs={4} className={classes.center}>
                                <Typography variant="caption">
                                    Rd {match.round} {match.year}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.center}>
                                <Typography variant="caption">
                                    {moment(match.date).format('ddd DD MMM hh:mm A')}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.center}>
                                <Typography variant="caption">{match.location}</Typography>
                            </Grid>
                        </Grid>
                        <Tabs value={value} variant="fullWidth" className={classes.tabs}>
                            <Tab value={routes.odds} label="Odds" to={routes.odds} component={Link} />
                            <Tab
                                value={routes.atHome}
                                label={`${match.homeTeam} At Home`}
                                to={routes.atHome}
                                component={Link}
                            />
                            <Tab value={routes.combined} label="All Grounds" to={routes.combined} component={Link} />
                            <Tab value={routes.stats} label="Stats" to={routes.stats} component={Link} />
                        </Tabs>
                    </div>
                );
            }}
        </FirebaseObject>
    );
};
