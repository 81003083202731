import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Grid, makeStyles, Typography, Tabs, Tab } from '@material-ui/core';
import { common, grey } from '@material-ui/core/colors';

interface HeaderProps {
    code: string;
    year: string;
}

const useStyles = makeStyles({
    header: {
        backgroundColor: common.white,
        boxShadow: 'none',
        borderBottom: `solid 1px ${grey[300]}`,
    },
    logo: {
        textAlign: 'left',
        padding: 8,
        paddingBottom: 0,
    },
    year: {
        textAlign: 'center',
        color: grey[700],
        padding: 8,
        paddingTop: 16,
        paddingBottom: 0,
    },
    code: {
        textAlign: 'right',
        padding: 8,
        paddingBottom: 0,
        '& img.epl': {
            width: 100,
        },
    },
    tabs: {
        color: grey[700],
        display: 'flex',
        justifyContent: 'space-evenly',
    },
});

export function Header(props: HeaderProps) {
    const { pathname } = useLocation();

    const routes = {
        code: `/${props.code}/${props.year}`,
        strategy: `/${props.code}/strategy/2019`,
        ladder: `/${props.code}/ladder/${props.year}`,
        teams: `/${props.code}/${props.year}/teams`,
    };

    const value = pathname.startsWith(routes.teams)
        ? routes.teams
        : pathname.startsWith(routes.ladder)
        ? routes.ladder
        : pathname.startsWith(routes.strategy)
        ? routes.strategy
        : routes.code;

    const classes = useStyles();
    return (
        <AppBar className={classes.header} id="header">
            <Grid container spacing={0}>
                <Grid className={classes.logo} item xs={4}>
                    <Link to="/">
                        <img src="/content/punters-hq.svg" alt="Punters HQ" />
                    </Link>
                </Grid>
                <Grid className={classes.year} item xs={4}>
                    <Typography variant="h4">{props.year}</Typography>
                </Grid>
                <Grid className={classes.code} item xs={4}>
                    <Link to={`/${props.code}`}>
                        <img className={props.code} src={`/content/${props.code}.svg`} alt={props.code.toUpperCase()} />
                    </Link>
                </Grid>
            </Grid>
            <Tabs className={classes.tabs} value={value} variant="fullWidth">
                <Tab value={routes.code} label="Draw" to={routes.code} component={Link} />
                <Tab value={routes.strategy} label="Strategy" to={routes.strategy} component={Link} />
                <Tab value={routes.ladder} label="Ladder" to={routes.ladder} component={Link} />
                <Tab value={routes.teams} label="Teams" to={routes.teams} component={Link} />
            </Tabs>
        </AppBar>
    );
}
