import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Footer } from '../components/Footer';
import { Menu, GenericHeader } from '../components';
import { SUPPORTED_CODES } from '../config';

const useStyles = makeStyles({
    home: {
        marginTop: '5rem',
        padding: '1rem',
        '& img.nrl': {
            height: 100,
        },
        '& img.afl': {
            height: 80,
        },
        '& img.epl': {
            width: 140,
            height: 80,
        },
    },
    logos: {
        marginTop: '1rem',
        marginBottom: '1rem',
        textAlign: 'center',
    },
    subHeader: {
        fontSize: '1.8rem',
        textAlign: 'center',
    },
    copy: {
        marginTop: '2rem',
        fontSize: '1.6rem',
        textAlign: 'justify',
    },
    underline: {
        textDecoration: 'underline',
    },
    pricing: {
        textAlign: 'justify',
        '& a': {
            marginLeft: 4,
            marginRight: 4,
        },
    },
});

const CODES = SUPPORTED_CODES.split(',');

export function LandingPage() {
    const classes = useStyles();
    return (
        <>
            <GenericHeader title="" />
            <main className={classes.home}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={classes.subHeader} variant="h2">
                            <span className={classes.underline}>Welcome</span> to Punters HQ.
                        </Typography>
                        <Typography className={classes.copy} variant="body1">
                            We provide statistics and tips in the convenience of a mobile app. We hope
                            you enjoy it!
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" className={classes.logos}>
                    {CODES.map(x => (
                        <Grid key={x} item xs={6}>
                            <Link to={`/${x}/2020`}>
                                <img src={`/content/${x}.svg`} alt={`${x} logo`} className={x} />
                            </Link>
                        </Grid>
                    ))}                    
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1" className={classes.pricing}>
                            If you are interested in becoming a subscriber, please see our
                            <Link to="/pricing">pricing</Link> page for more information.
                        </Typography>
                    </Grid>
                </Grid>
                <Menu />
            </main>
            <Footer />
        </>
    );
}
