import React from 'react';
import { Card, CardHeader, Typography, CardContent, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import { ProductType } from '@punters-hq/common';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Codes } from '.';

const useStyles = makeStyles({
    cardContent: {
        padding: '1rem',
        paddingTop: 0,
        '& h3': {
            fontSize: '2rem',
            marginRight: 4,
        },
        '& h4': {
            fontSize: '0.8rem',
            margin: 'auto 0',
        },
    },
    price: {
        display: 'flex',
        marginBottom: '0.5rem',
    },
    cardTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        '& h2': {
            textTransform: 'uppercase',
            color: grey[700],
            fontSize: '1rem',
            margin: 'auto 0',
        },
    },
    button: {
        textAlign: 'right',
    },
});

interface PriceProps {
    title: string;
    monthPrice: number;
    seasonPrice: number;
    productType: ProductType;
}

export function Price(props: PriceProps) {
    const dispatch = useDispatch();
    const onClick = () => dispatch(push('/sign-up'));
    const classes = useStyles();
    const bothCodes =
        props.productType === ProductType.BothCodesMonthly || props.productType === ProductType.BothCodesSeason;
    return (
        <Card>
            <CardHeader
                classes={{
                    title: classes.cardTitle,
                }}
                title={
                    <>
                        <Typography variant="h2">{props.title}</Typography>
                        <Codes selected={{ nrl: true, afl: bothCodes }} />
                    </>
                }
            />
            <CardContent className={classes.cardContent}>
                <Grid container alignContent="center" alignItems="center">
                    <Grid item xs={6} className={classes.price}>
                        <Typography variant="h3">${props.monthPrice}</Typography>
                        <Typography variant="h4">
                            <div>AUD + GST</div>
                            <div>per month</div>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.price}>
                        <Typography variant="h3">${props.seasonPrice}</Typography>
                        <Typography variant="h4">
                            <div>AUD + GST</div>
                            <div>per season</div>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.price}>
                        <Typography variant="caption">
                            For the period of the regular season (approximately 6 months). Subscribers will get any tips
                            for finals rounds free of charge.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.button}>
                        <Button color="secondary" variant="outlined" onClick={onClick}>
                            Start Free Trial
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
