import React from 'react';
import * as Model from '@punters-hq/common';
import { chain } from 'lodash';
import { ResultsTable } from '../components/Results';
import { homeAwayResultsPath } from '../utils';
import { FirebasePath } from '../components';

interface MatchProps {
    code: string;
    round: string;
    matchKey: string;
}

export function Match(props: MatchProps) {
    const values = props.matchKey.split('-');
    const [homeTeam, awayTeam] = [values[values.length - 2], values[values.length - 1]];
    const path = homeAwayResultsPath({ code: props.code, homeTeam, awayTeam });
    return (
        <FirebasePath path={path}>
            {(value: Model.MatchResult) => {
                const results: Model.Record[] = chain(value)
                    .values()
                    .sortBy(
                        x => x.year,
                        x => x.round,
                    )
                    .reverse()
                    .value();
                return <ResultsTable records={results} currentKey={props.matchKey} code={props.code} />;
            }}
        </FirebasePath>
    );
}
