import React, { useState } from 'react';
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { makeStyles, Grid, Typography, Input, InputAdornment } from '@material-ui/core';
import { grey, common, teal } from '@material-ui/core/colors';
import { Product } from '@punters-hq/common';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, createSubscriptionAsync, startLoading } from '../../store';
import { Loading, ProgressButton } from '../../components';
import { LocalOffer } from '@material-ui/icons';

interface PaymentDetailsProps {
    product: Product;
    codes: string[];
}

const useStyles = makeStyles({
    button: {
        marginTop: '1rem',
        textAlign: 'right',
        '& .MuiButtonBase-root': {
            whiteSpace: 'nowrap',
        },
    },
    payment: {
        marginTop: '1rem',
        '& h2': {
            fontSize: '1rem',
            textTransform: 'uppercase',
            margin: 'auto 0',
            marginBottom: '0.5rem',
            color: grey[700],
        },
    },
    card: {
        marginBottom: '0.5rem',
        padding: 14,
        border: `2px dashed ${grey[300]}`,
        borderRadius: 3,
        backgroundColor: common.white,
    },
    promo: {
        marginBottom: '0.5rem',
        padding: 14,
        border: `2px dashed ${grey[300]}`,
        backgroundColor: common.white,
        borderRadius: 3,
        '& svg': {
            color: grey[300],
        },
    },
    input: {
        padding: 0,
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        fontSize: 16,
        textTransform: 'uppercase',
        color: teal[400],
        '&::placeholder': {
            color: teal[400],
        },
    },
    focused: {
        borderColor: `${grey[300]} !important`,
    },
    caption: {
        color: grey[500],
    },
    stripe: {
        display: 'flex',
        alignItems: 'flex-end',
        '& img': {
            height: 24,
        },
    },
});

const createOptions = (fontSize: string) => {
    return {
        style: {
            base: {
                fontSize,
                backgroundColor: common.white,
                textTransform: 'uppercase',
                color: teal[400],
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                '::placeholder': {
                    color: teal[400],
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };
};

function _PaymentDetails(props: PaymentDetailsProps & ReactStripeElements.InjectedStripeProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector<AppState, firebase.User | null>(state => state.data.user);
    const [promoCode, setPromoCode] = useState<string | null>(null);

    if (!user) {
        return <Loading />;
    }

    const onClick = async () => {
        dispatch(startLoading());
        const response = await props.stripe!.createPaymentMethod('card', {
            billing_details: {
                email: user.email,
                name: user.displayName,
            },
            metadata: {
                productType: props.product.type.toString(),
                price: props.product.price.toFixed(2),
                subscription: props.product.subscription.toString(),
                codes: props.codes.join(','),
            },
        });

        dispatch(createSubscriptionAsync.request({ payment: response, promoCode }));
    };

    return (
        <>
            <Grid container className={classes.payment}>
                <Grid item xs={12}>
                    <Typography variant="h2">Payment</Typography>
                    <CardElement className={classes.card} {...createOptions('16px')} />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        className={classes.promo}
                        placeholder="PROMO CODE"
                        fullWidth
                        disableUnderline
                        classes={{
                            focused: classes.focused,
                            input: classes.input,
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                <LocalOffer />
                            </InputAdornment>
                        }
                        onChange={event => {
                            setPromoCode(event.target.value);
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.caption} variant="caption">
                        Subscriptions can be cancelled at any time. No partial refunds for season subscriptions.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} className={classes.stripe}>
                    <img src="/content/powered_by_stripe.svg" alt="powered by stripe" />
                </Grid>
                <Grid item xs={6} className={classes.button}>
                    <ProgressButton onClick={onClick} color="secondary" variant="outlined">
                        Create Account
                    </ProgressButton>
                </Grid>
            </Grid>
        </>
    );
}

export const PaymentDetails = injectStripe(_PaymentDetails);
