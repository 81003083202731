import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { TeamLogo } from './TeamLogo';
import { teamResultsPath } from '../utils';
import { FirebaseKeys } from './FirebaseObject';

interface TeamProps {
    code: string;
}

function Years(props: { years: string[]; pathname: string }) {
    return (
        <tbody>
            {props.years.reverse().map(key => {
                return (
                    <tr key={key}>
                        <td className="center-align">
                            <Link to={`${props.pathname}/results/${key}`}>{key}</Link>
                        </td>
                    </tr>
                );
            })}
        </tbody>
    );
}

export const Team = (props: TeamProps) => {
    const { team } = useParams<{ team: string }>();
    const location = useLocation();
    const path = teamResultsPath({ code: props.code, team });
    return (
        <FirebaseKeys path={path}>
            {(years: string[]) => {
                return (
                    <table className="striped">
                        <thead>
                            <tr>
                                <th className="center-align">
                                    <TeamLogo code={props.code} team={team} />
                                </th>
                            </tr>
                        </thead>
                        <Years pathname={location.pathname} years={years} />
                    </table>
                );
            }}
        </FirebaseKeys>
    );
};
