import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect, RouteComponentProps } from 'react-router';
import { AuthContext, FirebaseContext } from './firebase';

export function withFirebase<T>(Component: React.FunctionComponent<T>) {
    return (props: any) => {
        return (
            <FirebaseContext.Consumer>
                {(app: firebase.app.App) => {
                    return <Component {...props} app={app} />;
                }}
            </FirebaseContext.Consumer>
        );
    };
}

export function withDatabase<T>(Component: React.FunctionComponent<T>) {
    return (props: any) => {
        return (
            <FirebaseContext.Consumer>
                {(app: firebase.app.App) => {
                    return <Component {...props} database={app.database()} />;
                }}
            </FirebaseContext.Consumer>
        );
    };
}

export function withAuth(
    Component: React.FunctionComponent<any>,
    notAuthenticated: (props: any) => React.ReactElement = () => <Redirect to="/" />,
) {
    return (props: RouteComponentProps<any> & { app: firebase.app.App }) => {
        const [user, initialising] = useAuthState(props.app.auth());
        if (!user || initialising) {
            return notAuthenticated(props);
        }

        return (
            <AuthContext.Provider value={user}>
                <Component {...props.match.params} />
            </AuthContext.Provider>
        );
    };
}
