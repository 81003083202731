import React from 'react';
import cx from 'classnames';
import { Product, SubscriptionType, ProductType } from '@punters-hq/common';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { blue, common } from '@material-ui/core/colors';
import { Check } from '@material-ui/icons';

const useStyles = makeStyles({
    details: {
        display: 'flex',
        textAlign: 'left',
        marginLeft: 3,
    },
    price: {
        fontSize: '1.8rem',
        display: 'flex',
        alignItems: 'center',
        '& span': {
            fontSize: '1.2rem',
        },
    },
    period: {
        fontSize: '0.8rem',
        marginLeft: 4,
    },
    selected: {
        backgroundColor: `${blue[50]} !important`,
        position: 'relative',
    },
    icon: {
        margin: 'auto 0',
        position: 'absolute',
        top: 5,
        right: 5,
        fontSize: 18,
    },
    button: {
        backgroundColor: common.white,
    },
});

interface ProductButtonProps extends ButtonProps {
    product: Product;
    selected: boolean;
    onSelectProduct: (type: ProductType) => void;
}

export function ProductButton(props: ProductButtonProps) {
    const { product, selected, className, onSelectProduct, ...rest } = props;
    const onClick = () => onSelectProduct(product.type);

    const classes = useStyles();
    return (
        <Button onClick={onClick} className={cx(className, classes.button, { [classes.selected]: selected })} {...rest}>
            <div className={classes.details}>
                <Typography className={classes.price} variant="h3">
                    <span>$</span>
                    {props.product.price}
                </Typography>
                <Typography className={classes.period} variant="h4">
                    <div>AUD + GST</div>
                    <div>per {SubscriptionType[props.product.subscription]}</div>
                </Typography>
            </div>
            {selected && <Check className={classes.icon} />}
        </Button>
    );
}
