import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    left: {
        textAlign: 'left',
        '& svg': {
            color: '#444',
            width: 46,
            height: 46,
        },
    },
    header: {
        textAlign: 'center',
        fontSize: '1rem',
        margin: 'auto',
        textTransform: 'uppercase',
    },
    right: {
        textAlign: 'right',
        '& svg': {
            color: '#444',
            width: 46,
            height: 46,
        },
    },
});

interface SubHeaderProps {
    header: string;
    navLeftPath: string;
    navRightPath: string;
}

export function SubHeader({ header, navLeftPath, navRightPath }: SubHeaderProps) {
    const classes = useStyles();
    return (
        <Grid container alignContent="center" alignItems="center">
            <Grid item xs={3} className={classes.left}>
                <Link to={navLeftPath}>
                    <ChevronLeft />
                </Link>
            </Grid>
            <Grid item xs={6}>
                <Typography className={classes.header} variant="h3">
                    {header}
                </Typography>
            </Grid>
            <Grid item xs={3} className={classes.right}>
                <Link to={navRightPath}>
                    <ChevronRight />
                </Link>
            </Grid>
        </Grid>
    );
}
