import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware, connectRouter, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import reducer, { State } from './reducer';
import rootSaga from './sagas';

export * from './actions';

export const history = createBrowserHistory();

export interface AppState {
    router: RouterState;
    data: State;
}

const rootReducer = combineReducers({
    router: connectRouter(history),
    data: reducer,
});

const composeEnhancers = composeWithDevTools({});
const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history))));

sagaMiddleware.run(rootSaga);

export default store;
