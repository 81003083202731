import React from 'react';
import { StatsDictionary, LadderPositions } from '@punters-hq/common';
import { getNumberWithOrdinal } from '../components/TeamLogo';
import { homeAwayTeamStatsPath, teamStatsPath, allPositionsPath, homeAwayPositionStatsPath } from '../utils';
import { FirebasePath, LoadingTableRow } from '../components';
import styles from './Stats.module.css';

interface StatsProps {
    code: string;
    round: string;
    matchKey: string;
}

const StatsRows = (props: { path: string; heading: string }) => {
    return (
        <FirebasePath path={props.path} loading={() => <LoadingTableRow />}>
            {(value: StatsDictionary) => {
                const winPercentage = value.winPercentage * 100;
                return (
                    <>
                        <tr className={styles.sub}>
                            <td colSpan={4}>{props.heading}</td>
                            <td className={styles['win-loss']}>{winPercentage.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Total Points</td>
                            <td>{value.totals.mean.toFixed(2)}</td>
                            <td>{value.totals.median.toFixed(2)}</td>
                            <td>{value.totals.stdDev.toFixed(2)}</td>
                            <td />
                        </tr>
                        <tr>
                            <td>Margin</td>
                            <td>{value.margins.mean.toFixed(2)}</td>
                            <td>{value.margins.median.toFixed(2)}</td>
                            <td>{value.margins.stdDev.toFixed(2)}</td>
                            <td />
                        </tr>
                    </>
                );
            }}
        </FirebasePath>
    );
};

export const Stats = (props: StatsProps) => {
    const items = props.matchKey.split('-');
    const [homeTeam, awayTeam] = [items[items.length - 2], items[items.length - 1]];
    return (
        <table className={`striped centered ${styles.stats}`}>
            <thead>
                <tr>
                    <th />
                    <th>Mean</th>
                    <th>Median</th>
                    <th>Std. Dev.</th>
                    <th>Win %</th>
                </tr>
            </thead>
            <tbody>
                <StatsRows
                    path={homeAwayTeamStatsPath({
                        code: props.code,
                        homeTeam,
                        awayTeam,
                    })}
                    heading={`${homeTeam} vs. ${awayTeam}`}
                />
                <StatsRows
                    path={homeAwayTeamStatsPath({
                        code: props.code,
                        homeTeam: awayTeam,
                        awayTeam: homeTeam,
                    })}
                    heading={`${awayTeam} vs. ${homeTeam}`}
                />
                <FirebasePath path={allPositionsPath({ code: props.code })} loading={() => <LoadingTableRow />}>
                    {(positions: LadderPositions) => {
                        const homePos = positions[homeTeam].position + 1;
                        const awayPos = positions[awayTeam].position + 1;
                        return (
                            <StatsRows
                                path={homeAwayPositionStatsPath({
                                    code: props.code,
                                    homePosition: homePos,
                                    awayPosition: awayPos,
                                })}
                                heading={`${getNumberWithOrdinal(homePos)} vs. ${getNumberWithOrdinal(awayPos)}`}
                            />
                        );
                    }}
                </FirebasePath>
                <StatsRows path={teamStatsPath({ code: props.code, team: homeTeam })} heading={`${homeTeam} 2019`} />
                <StatsRows path={teamStatsPath({ code: props.code, team: awayTeam })} heading={`${awayTeam} 2019`} />
            </tbody>
        </table>
    );
};
