import React, { useRef, useEffect } from 'react';
import cx from 'classnames';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Match } from '@punters-hq/common';
import { TeamLogo, Recommendations } from '.';
import { blue, common, grey } from '@material-ui/core/colors';
import { MatchScore } from './MatchScore';

interface MatchRowProps {
    code: string;
    shouldScroll: boolean;
    played: boolean;
    onRowClick: () => void;
    match: Match;
    date: string;
    round: string;
}

const useStyles = makeStyles({
    nrl: {
        '& img': {
            height: 36,
        },
    },
    epl: {
        '& img': {
            height: 36,
        },
    },
    afl: {
        '& img': {
            height: 24,
        },
    },
    versus: {
        margin: 'auto',
        fontSize: '1.2rem',
        textAlign: 'center',
    },
    teams: {
        display: 'flex',
        padding: '0 8px',
    },
    details: {
        paddingLeft: 8,
        color: grey[800],
        '& span': {
            display: 'inline-block',
        },
    },
    match: {
        marginBottom: 10,
    },
    badge: {
        background: blue[500],
        fontWeight: 'bold',
        color: common.white,
        borderRadius: 2,
        textAlign: 'center',
        minWidth: '3rem',
        padding: 4,
        fontSize: '0.7rem',
    },
    played: {
        textAlign: 'right',
    },
});

export function MatchRow({ code, shouldScroll, played, match, onRowClick, date, round }: MatchRowProps) {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (shouldScroll && ref && ref.current !== null) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [shouldScroll]);

    const classes = useStyles();
    return (
        <div ref={ref} className={classes.match} key={match.key}>
            <Grid container onClick={onRowClick} alignContent="center" alignItems="center">
                <Grid item xs={4} md={3}>
                    <div className={cx(classes.teams, classes[code as 'nrl' | 'afl'])}>
                        <TeamLogo code={code} team={match.homeTeam} />
                        <div className={classes.versus}>vs</div>
                        <TeamLogo code={code} team={match.awayTeam} />
                    </div>
                </Grid>
                <Grid item xs={5} md={6} className={classes.details}>
                    <Typography variant="caption">{date}</Typography>
                    <Typography variant="caption">{match.location}</Typography>
                </Grid>
                {played && (
                    <Grid item xs={3} md={3} className={classes.played}>
                        <MatchScore code={code} match={match} />
                    </Grid>
                )}
            </Grid>
            <Recommendations onClick={onRowClick} code={code} round={round} match={match} />
        </div>
    );
}
