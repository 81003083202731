import React from 'react';
import classnames from 'classnames';
import { Match, Record } from '@punters-hq/common';
import { FirebasePath } from './FirebasePath';
import { homeAwayResultsPath } from '../utils';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

interface MatchScoreProps {
    code: string;
    match: Match;
    className?: string;
}

const useStyles = makeStyles({
    winner: {
        fontWeight: 'bold',
    },
    loser: {
        color: grey[500],
    },
    center: {
        textAlign: 'center',
    },
    left: {
        textAlign: 'left',
        paddingLeft: 10,
    },
    right: {
        textAlign: 'right',
        paddingRight: 10,
    },
});

export const MatchScore = (props: MatchScoreProps) => {
    const classes = useStyles();
    const path = homeAwayResultsPath({
        code: props.code,
        homeTeam: props.match.homeTeam,
        awayTeam: props.match.awayTeam,
        matchKey: props.match.key,
    });
    return (
        <FirebasePath path={path}>
            {(value: Record) => {
                return (
                    <Grid className={props.className} container alignContent="center" alignItems="center">
                        <Grid className={classes.center} item xs={5}>
                            <Typography
                                variant="h5"
                                className={classnames({
                                    [classes.winner]: value.isHomeWin,
                                    [classes.loser]: !value.isHomeWin,
                                })}
                            >
                                {value.homeScore}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.center}>
                            <span>FT</span>
                        </Grid>
                        <Grid className={classes.center} item xs={5}>
                            <Typography
                                variant="h5"
                                className={classnames({
                                    [classes.winner]: !value.isHomeWin,
                                    [classes.loser]: value.isHomeWin,
                                })}
                            >
                                {value.awayScore}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        </FirebasePath>
    );
};
