import { createSelector } from 'reselect';
import { Code, BetRecommendations, BetRecommendation } from '@punters-hq/common';
import { get } from 'lodash';
import { AppState } from '.';

const getCodeData = (state: AppState, props: { code: string }) => {
    return state.data[props.code];
};

export const getCurrentBetRecommendations = createSelector(
    [getCodeData],
    (code: Code) => {
        const recos: BetRecommendation[] = get(code, `model.recommendations[${code.currentRound}]`);
        return reduceBetRecommendations(recos);
    },
);

export function reduceBetRecommendations(recos: BetRecommendation[]) {
    const recommendations: BetRecommendations = (recos || []).reduce(
        (accum: BetRecommendations, curr: BetRecommendation) => {
            const [home, away] = curr.matchKey!.split('-');
            if (!accum[`${home}-${away}`]) {
                accum[`${home}-${away}`] = [];
            }
            accum[`${home}-${away}`].push(curr);
            return accum;
        },
        {},
    );
    return recommendations;
}
