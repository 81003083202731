import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { Record, MatchResult } from '@punters-hq/common';
import { chain, sortBy } from 'lodash';
import { TeamLogo } from './TeamLogo';
import { homeAwayResultsPath } from '../utils';
import { FirebasePath } from './FirebasePath';

interface Results {
    currentKey?: string;
    code: string;
    year: string;
    homeTeam: string;
    awayTeam: string;
}

export const Results = (props: Results) => {
    const path = homeAwayResultsPath(props);
    return (
        <FirebasePath path={path}>
            {(value: MatchResult) => {
                const results: Record[] = chain(value)
                    .values()
                    .sortBy(
                        x => x.year,
                        x => x.round,
                    )
                    .reverse()
                    .value();
                return <ResultsTable code={props.code} records={results} currentKey={props.currentKey} />;
            }}
        </FirebasePath>
    );
};

interface ResultsTable {
    records: Record[];
    currentKey?: string;
    code: string;
}

const useStyles = makeStyles({
    winningTeam: {
        padding: 2,
        '& img': {
            height: 42,
        },
    },
    resultsTable: {
        borderTop: 'none !important',
    },
});

export const ResultsTable = (props: ResultsTable) => {
    const classes = useStyles();
    return (
        <table className={`responsive-table striped ${classes.resultsTable}`}>
            <thead>
                <tr>
                    <th>Round</th>
                    <th>Winner</th>
                    <th>Score</th>
                    <th>Margin</th>
                    <th>Total Points</th>
                    <th>Home Win?</th>
                    <th>Draw?</th>
                </tr>
            </thead>
            <tbody>
                {sortBy(
                    props.records,
                    x => Number(x.year),
                    x => Number(x.round),
                )
                    .reverse()
                    .map((x: Record) => {
                        const winningTeam = x.isHomeWin ? x.homeTeam : x.awayTeam;
                        return (
                            <tr key={x.key} className={classnames({ current: props.currentKey === x.key })}>
                                <td>
                                    Rd {x.round} {x.year}{' '}
                                </td>
                                <td className={classes.winningTeam}>
                                    <TeamLogo code={props.code} team={winningTeam} />
                                </td>
                                <td>
                                    {x.homeTeam} {x.homeScore} vs. {x.awayTeam} {x.awayScore}
                                </td>
                                <td>{x.margin}</td>
                                <td>{x.totalPoints}</td>
                                <td>{x.isHomeWin ? 'Yes' : 'No'}</td>
                                <td>{x.isDraw ? 'Yes' : 'No'}</td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};
