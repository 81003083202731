import React from 'react';
import classnames from 'classnames';
import { Record, MatchResult } from '@punters-hq/common';
import { chain } from 'lodash';
import { TeamLogo } from './TeamLogo';
import { FirebasePath } from './FirebasePath';
import { matchResultsPath } from '../utils';
import styles from './TeamForm.module.css';

interface TeamFormProps {
    code: string;
    team: string;
    year: string;
    pastGames: number;
}

const TeamBadge = (props: { team: string; winner: boolean; code: string }) => {
    return (
        <li className={classnames({ [styles.winner]: props.winner, [styles.loser]: !props.winner })}>
            <TeamLogo code={props.code} team={props.team} className={styles[props.code]} />
        </li>
    );
};

export const TeamForm = (props: TeamFormProps) => {
    return (
        <FirebasePath path={matchResultsPath(props)}>
            {(value: MatchResult) => {
                const records: Record[] = chain(value)
                    .values()
                    .sortBy(x => x.startDateTime)
                    .reverse()
                    .take(props.pastGames)
                    .value();
                return (
                    <ul className={classnames(styles.form, styles[props.code], props.code)}>
                        {records.map(x => {
                            const winningTeam = x.isHomeWin ? x.homeTeam : x.awayTeam;
                            const opponentTeam = props.team === x.homeTeam ? x.awayTeam : x.homeTeam;
                            return (
                                <TeamBadge
                                    key={x.key}
                                    code={props.code}
                                    team={opponentTeam}
                                    winner={props.team === winningTeam}
                                />
                            );
                        })}
                    </ul>
                );
            }}
        </FirebasePath>
    );
};
