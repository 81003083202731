import * as actions from './actions';
import { ActionType } from 'typesafe-actions';
import { Actions } from './constants';
import { SubscriptionInfo } from '@punters-hq/common';

export interface State {
    [path: string]: any;
    user: firebase.User | null;
    subscription: SubscriptionInfo | null;
    loading: boolean;
    nrl: {
        selectedRound: string | null;
    };
    afl: {
        selectedRound: string | null;
    };
}

const initialState: State = {
    user: null,
    subscription: null,
    loading: false,
    nrl: {
        selectedRound: null,
    },
    afl: {
        selectedRound: null,
    },
};

type ActionTypes = ActionType<typeof actions>;

export default function reducer(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Actions.SyncPathSuccess:
            return {
                ...state,
                [action.payload.path]: action.payload.data,
            };
        case Actions.StoreSelectedRound:
            return {
                ...state,
                [action.payload.code]: {
                    selectedRound: action.payload.round,
                },
            };
        case Actions.UpdateAuthStatus:
            return {
                ...state,
                user: action.payload,
            };
        case Actions.SignOut:
            return {
                ...state,
                user: null,
                subscription: null,
            };
        case Actions.CreateSubscriptionSuccess:
            return {
                ...state,
                subscription: action.payload,
            };
        case Actions.StartLoading:
            return {
                ...state,
                loading: true,
            };
        case Actions.StopLoading:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
