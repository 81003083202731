import React from 'react';
import { Team } from '@punters-hq/common';
import { Link } from 'react-router-dom';
import { TeamLogo } from '../components/TeamLogo';

interface TeamsProps {
    teams: Team[];
    code: string;
    year: string;
}

export const Teams = (props: TeamsProps) => {
    return (
        <table className="teams striped">
            <tbody>
                {props.teams.map(x => {
                    return (
                        <tr key={x.code} className="team">
                            <td className="center-align">
                                <Link to={`/${props.code}/${props.year}/teams/${x.code}`}>
                                    <TeamLogo code={props.code} team={x.code} />
                                </Link>
                            </td>
                            <td>
                                <Link to={`/${props.code}/${props.year}/teams/${x.code}`}>{x.name}</Link>
                            </td>
                            <td>
                                <Link to={`/${props.code}/${props.year}/teams/${x.code}`}>{x.code}</Link>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
