import React from 'react';
import { Header, Footer, Menu } from '../components';

interface PageProps {
    code: string;
    year: string;
    children: JSX.Element;
}

export function Page(props: PageProps) {
    return (
        <>
            <Header code={props.code} year={props.year} />
            <main className="body">
                {props.children}
                <Menu />
            </main>
            <Footer />
        </>
    );
}
