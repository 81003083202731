import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core';
import { red, grey } from '@material-ui/core/colors';
import { client } from '../client';
import { getAuthToken } from '../firebase';
import { ProgressButton } from './ProgressButton';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../store';

interface CancelSubscriptionButtonProps {
    user: firebase.User;
}

const useStyles = makeStyles({
    button: {
        color: red[500],
        borderColor: red[500],
        width: '100%',
        '& .MuiCircularProgress-root': {
            color: red[500],
        },
    },
    caption: {
        color: grey[500],
    },
});

function useCancel(
    user: firebase.User,
    startLoading: () => void,
    stopLoading: () => void,
): [() => Promise<void>, boolean] {
    const [cancelled, setCancelled] = useState(false);
    const api = useCallback(async () => {
        startLoading();
        client
            .delete('/subscription', {
                headers: {
                    Authorization: await getAuthToken(user),
                },
            })
            .then(async () => {
                setCancelled(true);
                await user.getIdToken(true);
                toast.success('Subscription successfully cancelled.');
            })
            .catch(() => {
                toast.error('Error occurred cancelling your subscription, please try again.');
            })
            .finally(() => {
                stopLoading();
            });
    }, [user, startLoading, stopLoading]);

    return [api, cancelled];
}

export function CancelSubscriptionButton(props: CancelSubscriptionButtonProps) {
    const dispatch = useDispatch();
    const [confirm, setConfirm] = useState(false);
    const [api, cancelled] = useCancel(props.user, () => dispatch(startLoading()), () => dispatch(stopLoading()));

    const onConfirm = () => setConfirm(true);
    const onCancel = () => api();

    const classes = useStyles();
    return (
        <ProgressButton
            className={classes.button}
            disabled={cancelled}
            onClick={!confirm ? onConfirm : onCancel}
            variant="outlined"
        >
            {cancelled ? 'Subscription Cancelled' : !confirm ? 'Cancel Subscription' : 'Are you sure?'}
        </ProgressButton>
    );
}
