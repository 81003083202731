import React from 'react';
import { Grid, Card, CardContent, Typography, List, ListItemText, ListItem, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { History, SportsRugby, TrackChanges } from '@material-ui/icons';

import { Products, ProductType } from '@punters-hq/common';
import { Menu, Footer, Price, FirebasePath, GenericHeader } from '../components';

const useStyles = makeStyles({
    container: {
        marginTop: '5rem',
    },
    item: {
        padding: '0.5rem',
    },
    list: {
        padding: 0,
    },
    listItem: {
        padding: 0,
        '& svg': {
            minWidth: 42,
        },
    },
    cardContent: {
        padding: '1rem',
    },
});

function CodeLink({ code, path }: { code: string; path: string }) {
    return <Link to={`${code.toLowerCase()}/${path}`}>{code}</Link>;
}

export function Pricing() {
    const classes = useStyles();
    return (
        <>
            <GenericHeader title="Pricing" />
            <main className={classes.container}>
                <Grid container>
                    <Grid item xs={12} className={classes.item}>
                        <Card>
                            <CardContent className={classes.cardContent}>
                                <List className={classes.list}>
                                    <ListItem className={classes.listItem}>
                                        <ListItemIcon>
                                            <SportsRugby />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body1">
                                                Weekly bet recommendations from our quantitative model
                                            </Typography>
                                            <Typography variant="caption">
                                                See past performance for <CodeLink code="NRL" path="strategy/2019" />
                                                &amp; <CodeLink code="AFL" path="strategy/2019" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <ListItemIcon>
                                            <TrackChanges />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body1">Bet tracking tool</Typography>
                                            <Typography variant="caption">
                                                Track your own bets through our mobile app to ensure your performance
                                                matches our model
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <ListItemIcon>
                                            <History />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body1">2 week free trial</Typography>
                                            <Typography variant="caption">
                                                Try our product for 2 weeks, free of charge. Cancel your subscription at
                                                any time.
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <FirebasePath path="/products">
                        {(products: Products) => {
                            return (
                                <>
                                    <Grid item xs={12} md={6} className={classes.item}>
                                        <Price
                                            productType={ProductType.SingleCodeMonthly}
                                            title="Single code"
                                            monthPrice={products[ProductType.SingleCodeMonthly].price}
                                            seasonPrice={products[ProductType.SingleCodeSeason].price}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} className={classes.item}>
                                        <Price
                                            productType={ProductType.BothCodesMonthly}
                                            title="Both codes"
                                            monthPrice={products[ProductType.BothCodesMonthly].price}
                                            seasonPrice={products[ProductType.BothCodesSeason].price}
                                        />
                                    </Grid>
                                </>
                            );
                        }}
                    </FirebasePath>
                </Grid>
                <Menu />
            </main>
            <Footer />
        </>
    );
}
