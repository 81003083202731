/// <reference types="stripe-v3" />
import { createAsyncAction, action } from 'typesafe-actions';
import { Actions } from './constants';
import { SubscriptionInfo } from '@punters-hq/common';

export const syncPathAsync = createAsyncAction(Actions.SyncPath, Actions.SyncPathSuccess, Actions.SyncPathError)<
    string,
    { path: string; data: any },
    Error
>();

export const createSubscriptionAsync = createAsyncAction(
    Actions.CreateSubscription,
    Actions.CreateSubscriptionSuccess,
    Actions.CreateSubscriptionError,
)<{ payment: stripe.PaymentMethodResponse; promoCode: string | null }, SubscriptionInfo, stripe.Error>();

export const watchAuthStatus = () => action(Actions.WatchAuthStatus);
export const updateAuthStatus = (user: firebase.User | null) => action(Actions.UpdateAuthStatus, user);
export const signOut = () => action(Actions.SignOut);
export const storeSelectedRound = (code: string, round: string) => action(Actions.StoreSelectedRound, { code, round });

export const startLoading = () => action(Actions.StartLoading);
export const stopLoading = () => action(Actions.StopLoading);
