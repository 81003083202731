import React from 'react';
import { useObject, useListKeys } from 'react-firebase-hooks/database';
import { Loading } from './Loading';
import { withDatabase } from '../context';

interface FirebaseObjectProps {
    database: firebase.database.Database;
    path: string;
    render?: (data: any) => React.ReactElement;
    children?: (data: any) => React.ReactElement;
    loading: () => React.ReactElement | null;
    error: () => React.ReactElement | null;
}

const _FirebaseObject = (props: FirebaseObjectProps) => {
    const ref = props.database.ref(props.path);
    const [snapshot, loading, error] = useObject(ref);

    if (error) {
        return props.error();
    }

    if (!snapshot || loading) {
        return props.loading();
    }

    if (props.children) {
        return props.children(snapshot.val());
    }

    if (props.render) {
        return props.render(snapshot.val());
    }

    return null;
};

_FirebaseObject.defaultProps = {
    loading: () => <Loading />,
    error: () => null,
};

const _FirebaseKeys = (props: FirebaseObjectProps) => {
    const ref = props.database.ref(props.path);
    const [keys, loading, error] = useListKeys(ref);

    if (error) {
        return props.error();
    }

    if (!keys || loading) {
        return props.loading();
    }

    if (props.children) {
        return props.children(keys);
    }

    if (props.render) {
        return props.render(keys);
    }

    return null;
};

_FirebaseKeys.defaultProps = {
    loading: () => <Loading />,
    error: () => null,
};

export const FirebaseObject = withDatabase(_FirebaseObject);
export const FirebaseKeys = withDatabase(_FirebaseKeys);
