import React from 'react';
import classnames from 'classnames';
import { values } from 'lodash';
import { Typography } from '@material-ui/core';

import { MatchOdds, TotalsOdds, SpreadOdds, Match } from '@punters-hq/common';
import { FirebasePath, Recommendations, LoadingTableRow, FirebaseObject } from '../components';
import { oddsPath, matchPath } from '../utils';
import { ArrowUpward, ArrowDownward, Add, Remove } from '@material-ui/icons';
import styles from './Odds.module.css';

interface OddsProps {
    code: string;
    year: string;
    round: string;
    matchKey: string;
}

const LineOdds = ({ value, odds }: { value: string; odds: number }) => {
    const number = Number(value);
    const icon = number > 0 ? <Add /> : <Remove />;
    return (
        <div className={styles.line}>
            {icon}
            <span>
                {Math.abs(number)} ${odds.toFixed(2)}
            </span>
        </div>
    );
};

export function Odds(props: OddsProps) {
    return (
        <>
            <FirebaseObject path={matchPath(props)}>
                {(match: Match) => {
                    return <Recommendations code={props.code} round={props.round} match={match} />;
                }}
            </FirebaseObject>
            <table className={classnames('striped centered', styles.odds)}>
                <thead>
                    <tr>
                        <th>Bookie</th>
                        <th>H2H</th>
                        <th>Total Points</th>
                        <th>Line</th>
                    </tr>
                </thead>
                <tbody>
                    <FirebasePath path={oddsPath(props)} loading={() => <LoadingTableRow />}>
                        {(matchOdds: MatchOdds) => {
                            if (!matchOdds || !matchOdds.odds) {
                                return (
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <Typography variant="body1">No odds available</Typography>
                                            </td>
                                        </tr>
                                    </tbody>
                                );
                            }

                            const odds = values(matchOdds.odds);
                            return (
                                <>
                                    {odds.map(x => {
                                        const h2h = x.markets['h2h'] as number[];
                                        const totals = x.markets['totals'] as TotalsOdds;
                                        const spread = x.markets['spreads'] as SpreadOdds;
                                        return (
                                            <tr key={x.site}>
                                                <td>
                                                    <span
                                                        title={x.site}
                                                        className={classnames(styles.bookmaker, styles[x.site])}
                                                    />
                                                </td>
                                                <td>
                                                    ${h2h[0].toFixed(2)} / ${h2h[1].toFixed(2)}
                                                </td>
                                                <td>
                                                    {totals && (
                                                        <>
                                                            <div className={styles.totals}>
                                                                <ArrowUpward />
                                                                <span>
                                                                    {totals.points[0]} ${totals.odds[0].toFixed(2)}
                                                                </span>
                                                            </div>
                                                            <div className={styles.totals}>
                                                                <ArrowDownward />
                                                                <span>
                                                                    {totals.points[1]} ${totals.odds[1].toFixed(2)}
                                                                </span>
                                                            </div>
                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    {spread && (
                                                        <>
                                                            <LineOdds value={spread.points[0]} odds={spread.odds[0]} />
                                                            <LineOdds value={spread.points[1]} odds={spread.odds[1]} />
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                            );
                        }}
                    </FirebasePath>
                </tbody>
            </table>
        </>
    );
}
