import React, { useState } from 'react';
import * as firebase from 'firebase/app';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { auth } from 'firebaseui';
import { useDispatch } from 'react-redux';

import { Footer, Menu, GenericHeader } from '../components';
import { app } from '../firebase';
import { updateAuthStatus } from '../store';

const useStyles = makeStyles({
    container: {
        padding: '1rem',
        marginTop: '5rem',
    },
    center: {
        textAlign: 'center'
    },
    signIn: {
        marginTop: '2rem',
        '& .firebaseui-container.mdl-card': {
            background: 'transparent',
        },
    },
});

export function SignIn() {
    const dispatch = useDispatch();
    const [signedIn, setSignedIn] = useState(false);

    const authConfig: auth.Config = {
        signInFlow: 'redirect',
        signInSuccessUrl: '/',
        callbacks: {
            signInFailure: async (error: auth.AuthUIError) => {
                dispatch(updateAuthStatus(null));
            },
            signInSuccessWithAuthResult: (result: any, redirect?: string) => {
                dispatch(updateAuthStatus(result.user));
                setSignedIn(true);
                return true;
            },
        },
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
    };

    const classes = useStyles();
    return (
        <>
            <GenericHeader title="Sign In" />
            <main>
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                        <Typography className={classes.center} variant="body1">
                            {!signedIn ? 'Please choose your preferred sign-in method' : 'Signed in, please wait...'}
                        </Typography>
                        <StyledFirebaseAuth
                            className={classes.signIn}
                            uiConfig={authConfig}
                            firebaseAuth={app.auth()}
                        />
                    </Grid>
                </Grid>
                <Menu />
            </main>
            <Footer />
        </>
    );
}
