import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

import { Footer, Menu, SubscriptionDetails, GenericHeader } from '../components';

const useStyles = makeStyles({
    container: {
        marginTop: '5rem',
        padding: '1rem',
    },
});

export function Account() {
    const classes = useStyles();
    return (
        <>
            <GenericHeader title="Account" />
            <main>
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                        <SubscriptionDetails />
                    </Grid>
                </Grid>
                <Menu />
            </main>
            <Footer />
        </>
    );
}
