import React from 'react';
import classnames from 'classnames';
import { Position } from '@punters-hq/common';
import styles from './TeamLogo.module.css';
import { teamPositionPath } from '../utils';
import { FirebaseObject } from './FirebaseObject';

export function getNumberWithOrdinal(n: number): string {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

interface TeamLogoProps {
    code: string;
    team: string;
    className?: string;
}

export function TeamLogo(props: TeamLogoProps) {
    return (
        <img className={props.className} src={`/content/${props.code}/${props.team}.svg`} alt={`${props.team} logo`} />
    );
}

interface TeamLogoWithPositionProps {
    placement: 'left' | 'right';
}

export function TeamLogoWithPosition(props: TeamLogoWithPositionProps & TeamLogoProps) {
    return (
        <FirebaseObject path={teamPositionPath(props)}>
            {(value: Position) => {
                return (
                    <span className={classnames(styles['logo-position'])}>
                        <span className={styles[`pos-${props.placement}`]}>
                            {getNumberWithOrdinal(value.position + 1)}
                        </span>
                        <TeamLogo {...props} className={classnames(styles.logo, styles[props.code])} />
                    </span>
                );
            }}
        </FirebaseObject>
    );
}
