import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import {
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_DB_URL,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGE_SENDER_ID,
    FIREBASE_APP_ID,
} from './config';

const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    databaseURL: FIREBASE_DB_URL,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGE_SENDER_ID,
    appId: FIREBASE_APP_ID,
};

let _app: firebase.app.App;
let _context: React.Context<firebase.app.App>;
let _authContext: React.Context<any>;

export const init = () => {
    if (_app) {
        return _app;
    }
    _app = firebase.initializeApp(firebaseConfig, FIREBASE_PROJECT_ID);
    _context = React.createContext(_app);
    _authContext = React.createContext(null);
    return _app;
};

async function getAuthToken(user: firebase.User): Promise<string> {
    const token = await user.getIdToken();
    return `Bearer ${token}`;
}

export { _app as app, _context as FirebaseContext, _authContext as AuthContext, getAuthToken };
