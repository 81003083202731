import React from 'react';
import { ResultsTable } from '../components/Results';
import { useParams } from 'react-router-dom';
import { MatchResult, Record } from '@punters-hq/common';
import { chain } from 'lodash';
import { FirebasePath, TeamLogo, SubHeader } from '../components';
import { matchResultsPath } from '../utils';
import styles from './TeamResults.module.css';

export const TeamResultsHeader = () => {
    const params = useParams<{ year: string; team: string; code: string }>();
    return (
        <table className={styles.team}>
            <thead>
                <tr>
                    <th className="center-align">
                        <TeamLogo code={params.code} team={params.team} />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="center-align">
                        <SubHeader
                            header={params.year}
                            navLeftPath={`/${params.code}/${params.year}/teams/${params.team}/results/${Number(
                                params.year,
                            ) - 1}`}
                            navRightPath={`/${params.code}/${params.year}/teams/${params.team}/results/${Number(
                                params.year,
                            ) + 1}`}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export const TeamResults = () => {
    const params = useParams<{ year: string; team: string; code: string }>();
    return (
        <FirebasePath path={matchResultsPath(params)}>
            {(value: MatchResult) => {
                const results: Record[] = chain(value)
                    .values()
                    .sortBy(
                        x => x.year,
                        x => x.round,
                    )
                    .reverse()
                    .value();
                return <ResultsTable records={results} code={params.code} />;
            }}
        </FirebasePath>
    );
};
