import { take, fork, call, select, put } from '@redux-saga/core/effects';
import ReduxSagaFirebase from 'redux-saga-firebase';
import { AxiosResponse } from 'axios';
import { eventChannel } from '@redux-saga/core';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';

import { SubscriptionInfo } from '@punters-hq/common';
import { syncPathAsync, updateAuthStatus, createSubscriptionAsync, stopLoading } from './actions';
import { init, getAuthToken } from '../firebase';
import { Actions } from './constants';
import { AppState } from '.';
import { get } from 'lodash';
import { client } from '../client';

const app = init();
const rsf = new ReduxSagaFirebase(app);

function* syncPathSaga() {
    while (true) {
        const action: ReturnType<typeof syncPathAsync.request> = yield take(Actions.SyncPath);

        const data = yield select((x: AppState) => x.data[action.payload]);
        if (data) {
            yield put(syncPathAsync.success({ path: action.payload, data }));
            continue;
        }

        const options = {
            successActionCreator: syncPathAsync.success,
            transform: (x: { value: any }) => {
                return {
                    path: action.payload,
                    data: x.value,
                };
            },
        };
        yield fork(rsf.database.sync, action.payload, options, 'value');
    }
}

function* watchAuthStatusSaga() {
    while (true) {
        yield take(Actions.WatchAuthStatus);
        yield fork(authenticationSaga);
    }
}

function* authenticationSaga() {
    function getAuthChannel() {
        const channel = eventChannel(emit => {
            const unsubscribe = app.auth().onAuthStateChanged(user => {
                emit({ user });
            });
            return unsubscribe;
        });
        return channel;
    }

    const channel = yield call(getAuthChannel);
    const { user } = yield take<{ user: firebase.User | null }>(channel);

    if (user) {
        yield call(() => user.getIdToken(true));
    }

    yield put(updateAuthStatus(user));
}

function* signOutSaga() {
    while (true) {
        yield take(Actions.SignOut);
        yield call(() => app.auth().signOut());
    }
}

export function* createSubscriptionSaga() {
    while (true) {
        const action: ReturnType<typeof createSubscriptionAsync.request> = yield take(Actions.CreateSubscription);

        if (action.payload.payment.error) {
            yield put(createSubscriptionAsync.failure(action.payload.payment.error));
            yield put(stopLoading());
            toast.warn('Something went wrong, please enter your payment details again.');
            continue;
        }

        try {
            const user: firebase.User = yield select(state => state.data.user);
            const authToken = yield call(() => getAuthToken(user));
            const response: AxiosResponse<SubscriptionInfo> = yield call(
                client.post,
                '/subscription',
                {
                    paymentMethod: action.payload.payment.paymentMethod,
                    promoCode: (action.payload.promoCode || '').toUpperCase(),
                },
                {
                    headers: {
                        Authorization: authToken,
                    },
                },
            );

            toast.success('Subscription created successfully!');
            yield put(createSubscriptionAsync.success(response.data));
            yield put(push('/sign-up/complete'));
        } catch (error) {
            yield put(createSubscriptionAsync.failure(error.response));
            const message = get(error.response, 'data.message');
            toast.error(message || 'Something went wrong creating your subscription, please try again.');
        } finally {
            yield put(stopLoading());
        }
    }
}

export default function* rootSaga() {
    yield fork(syncPathSaga);
    yield fork(watchAuthStatusSaga);
    yield fork(signOutSaga);
    yield fork(createSubscriptionSaga);
}
