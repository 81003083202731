import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { Round } from './Round';
import { FirebasePath } from '../components';
import { currentRoundPath } from '../utils';
import { AppState } from '../store';

interface CurrentRoundProps {
    code: string;
    year: string;
}

export const CurrentRound = (props: CurrentRoundProps) => {
    const selectedRound = useSelector<AppState, string | null>(state => get(state.data, `${props.code}.selectedRound`));
    if (selectedRound) {
        return <Round code={props.code} year={props.year} round={selectedRound} />;
    }

    return (
        <FirebasePath path={currentRoundPath(props)}>
            {(currentRound: string) => {
                return <Round code={props.code} year={props.year} round={currentRound} />;
            }}
        </FirebasePath>
    );
};
