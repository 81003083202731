import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import styles from './Loading.module.css';

export const Loading = () => {
    return (
        <div className={styles.loading}>
            <Skeleton width="80%" height="20px" />
            <Skeleton width="80%" height="20px" />
        </div>
    );
};

const useStyles = makeStyles({
    row: {
        paddingLeft: 20,
        paddingRight: 20,
    },
});

export const LoadingTableRow = () => {
    const classes = useStyles();
    return (
        <tr>
            <td colSpan={5} className={classes.row}>
                <Skeleton width="100%" height="20px" />
            </td>
        </tr>
    );
};

export const LoadingLogo = () => {
    return (
        <div className={styles.loading}>
            <div>
                <img src="/content/punters-hq.svg" alt="Punters HQ logo" />
                <h1>Loading stats...</h1>
            </div>
        </div>
    );
};
