import React, { useState } from 'react';
import cx from 'classnames';
import {
    Fab,
    makeStyles,
    Menu as MuiMenu,
    useMediaQuery,
    MenuItem,
    Divider,
    ListItemIcon,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Badge,
} from '@material-ui/core';
import { Menu as MuiMenuIcon, Close, Person } from '@material-ui/icons';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { common } from '@material-ui/core/colors';

export const MENU_BUTTON_ZINDEX = 10000;

const useStyles = makeStyles({
    button: {
        position: 'fixed',
        bottom: 10,
        left: 10,
        opacity: 0.6,
        zIndex: MENU_BUTTON_ZINDEX,
    },
    break: {
        left: '21%',
    },
    menu: {
        minWidth: 150,
        paddingBottom: 26,
    },
    menuItem: {
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
    avatar: {
        width: 22,
        height: 22,
        border: `solid 1px ${common.white}`,
    },
});

const LoggedInUser = React.forwardRef(({ user }: { user: firebase.User }, ref) => {
    return (
        <>
            <MenuItem innerRef={ref}>
                {!user.photoURL && (
                    <ListItemIcon>
                        <Person />
                    </ListItemIcon>
                )}
                {user.photoURL && (
                    <ListItemAvatar>
                        <Avatar src={user.photoURL} />
                    </ListItemAvatar>
                )}
                <ListItemText primary={user.displayName} secondary={user.email} />
            </MenuItem>
            <Divider />
        </>
    );
});

const MenuIcon = ({
    user,
    showMenu,
    avatarClassName,
}: {
    user: firebase.User | null;
    showMenu: boolean;
    avatarClassName: string;
}) => {
    if (!user) {
        return showMenu ? <Close /> : <MuiMenuIcon />;
    }

    const avatar = !user.photoURL ? (
        <Person className={avatarClassName} />
    ) : (
        <Avatar className={avatarClassName} src={user.photoURL} />
    );
    return (
        <Badge
            overlap="circle"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            badgeContent={avatar}
        >
            {showMenu ? <Close /> : <MuiMenuIcon />}
        </Badge>
    );
};

export function Menu() {
    const [showMenu, setShowMenu] = useState(false);
    const toggleMenu = () => setShowMenu(!showMenu);
    const user = useSelector<AppState, firebase.User | null>(state => state.data.user);

    const anchorEl = useRef(null);
    const matches = useMediaQuery('(min-width: 1023px)');
    const classes = useStyles();
    return (
        <>
            <MuiMenu classes={{ list: classes.menu }} open={showMenu} anchorEl={anchorEl.current} onClose={toggleMenu}>
                {user && <LoggedInUser user={user} />}
                <MenuItem className={classes.menuItem}>
                    <Link to="/">Home</Link>
                </MenuItem>
                {user && (
                    <MenuItem className={classes.menuItem}>
                        <Link to="/account">Account</Link>
                    </MenuItem>
                )}
                {!user && (
                    <MenuItem className={classes.menuItem}>
                        <Link to="/sign-in">Sign In</Link>
                    </MenuItem>
                )}
                {!user && (
                    <MenuItem className={classes.menuItem}>
                        <Link to="/pricing">Sign Up</Link>
                    </MenuItem>
                )}
                {user && (
                    <MenuItem className={classes.menuItem}>
                        <Link to="/sign-out">Sign Out</Link>
                    </MenuItem>
                )}
            </MuiMenu>
            <Fab
                ref={anchorEl}
                color="secondary"
                className={cx(classes.button, {
                    [classes.break]: matches,
                })}
                size="small"
                onClick={toggleMenu}
            >
                <MenuIcon user={user} showMenu={showMenu} avatarClassName={classes.avatar} />
            </Fab>
        </>
    );
}
