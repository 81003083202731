import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { CssBaseline, Theme, createMuiTheme } from '@material-ui/core';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { blue, grey, common } from '@material-ui/core/colors';
import { ThemeProvider } from '@material-ui/styles';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import 'react-toastify/dist/ReactToastify.css';

import { init, FirebaseContext } from './firebase';
import { routes } from './routes';
import store, { history, watchAuthStatus } from './store';
import * as serviceWorker from './serviceWorker';
import { MENU_BUTTON_ZINDEX } from './components';
import { SENTRY_DSN, GOOGLE_TAG_ID } from './config';
import './index.css';

const tagManagerArgs = {
    gtmId: GOOGLE_TAG_ID as string,
};

TagManager.initialize(tagManagerArgs);

Sentry.init({ dsn: SENTRY_DSN });

const app = init();

const theme: Theme = createMuiTheme({
    typography: {
        fontFamily: "'Rubik', sans-serif;",
    },
    palette: {
        secondary: blue,
        text: {
            primary: grey[800],
        },
    },
    overrides: {
        MuiButton: {
            root: {
                backgroundColor: common.white,
                '&$disabled': {
                    backgroundColor: common.white,
                },
            },
        },
        MuiStepLabel: {
            root: {
                whiteSpace: 'nowrap',
            },
            active: {
                whiteSpace: 'nowrap',
            },
        },
        MuiStepIcon: {
            root: {
                '&$active': {
                    color: blue[500],
                },
            },
        },
        MuiInputBase: {
            root: {
                '&$focused': {
                    borderColor: blue[500],
                },
            },
        },
        MuiButtonGroup: {
            grouped: {
                padding: 12,
                '& .MuiButton-label': {
                    display: 'block',
                    textAlign: 'left',
                },
            },
        },
    },
});

const App = () => {
    useEffect(() => {
        store.dispatch(watchAuthStatus());
    }, []);

    return (
        <FirebaseContext.Provider value={app}>
            <ToastContainer position="bottom-right" style={{ zIndex: MENU_BUTTON_ZINDEX + 1 }} />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Provider store={store}>
                    <ConnectedRouter history={history}>{routes()}</ConnectedRouter>
                </Provider>
            </ThemeProvider>
        </FirebaseContext.Provider>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
