import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        padding: '8px 14px',
        textDecoration: 'none',
        color: grey[800],
        '& svg': {
            margin: 'auto 0',
        },
        '& p': {
            margin: 'auto 0',
            fontSize: '0.9rem',
        },
    },
});

export function AccessDenied() {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={12}>
                <Link to="/sign-up" className={classes.container}>
                    <Typography variant="body1">Sign up to see bet our recommendations</Typography>
                    <ChevronRight fontSize="small" />
                </Link>
            </Grid>
        </Grid>
    );
}
