import React from 'react';
import { Record } from '@punters-hq/common';
import { ResultsTable } from '../components/Results';
import { values, sortBy } from 'lodash';
import { FirebasePath } from '../components';
import { homeAwayResultsPath } from '../utils';

interface CombinedProps {
    code: string;
    round: string;
    matchKey: string;
}

export const Combined = (props: CombinedProps) => {
    const items = props.matchKey.split('-');
    const [homeTeam, awayTeam] = [items[items.length - 2], items[items.length - 1]];
    const homeAwayPath = homeAwayResultsPath({
        code: props.code,
        homeTeam,
        awayTeam,
    });
    const awayHomePath = homeAwayResultsPath({
        code: props.code,
        homeTeam: awayTeam,
        awayTeam: homeTeam,
    });
    return (
        <FirebasePath path={homeAwayPath}>
            {(homeAway: Record[]) => {
                return (
                    <FirebasePath path={awayHomePath}>
                        {(awayHome: Record[]) => {
                            const records = sortBy(
                                values(homeAway).concat(values(awayHome)),
                                x => x.year,
                                x => x.round,
                            ).reverse();
                            return <ResultsTable code={props.code} records={records} currentKey={props.matchKey} />;
                        }}
                    </FirebasePath>
                );
            }}
        </FirebasePath>
    );
};
