import moment from 'moment';
import { Match } from '@punters-hq/common';

export function hasBeenPlayed(match: Match) {
    const start = moment(match.date).local();
    return start.add(100, 'minute').isBefore(moment());
}

export function matchPath(args: { code: string; year: string; round: string; matchKey: string }) {
    return `/${args.code}/draw/${args.year}/${args.round}/matches/${args.matchKey}`;
}

export function homeAwayResultsPath(args: { code: string; homeTeam: string; awayTeam: string; matchKey?: string }) {
    if (args.matchKey) {
        return `/${args.code}/stats/homeAwayTeamResults/${args.homeTeam}-${args.awayTeam}/${args.matchKey}`;
    }
    return `/${args.code}/stats/homeAwayTeamResults/${args.homeTeam}-${args.awayTeam}`;
}

export function matchResultsPath(args: { code: string; team: string; year?: string | number }) {
    return `/${args.code}/stats/teamResults/${args.team}/${args.year || 2019}`;
}

export function teamResultsPath(args: { code: string; team: string }) {
    return `/${args.code}/stats/teamResults/${args.team}`;
}

export function teamPositionPath(args: { code: string; team: string; year?: string | number }) {
    return `/${args.code}/stats/allStandings/${args.year || 2019}/${args.team}`;
}

export function allPositionsPath(args: { code: string; year?: string | number }) {
    return `/${args.code}/stats/allStandings/${args.year || 2019}`;
}

export function homeAwayTeamStatsPath(args: { code: string; homeTeam: string; awayTeam: string }) {
    return `/${args.code}/stats/homeAwayTeamStats/${args.homeTeam}-${args.awayTeam}`;
}

export function teamStatsPath(args: { code: string; team: string; year?: string | number }) {
    return `/${args.code}/stats/teamStats/${args.team}/${args.year || 2019}`;
}

export function homeAwayPositionStatsPath(args: { code: string; homePosition: number; awayPosition: number }) {
    return `/${args.code}/stats/homeAwayPositionStats/${args.homePosition}-${args.awayPosition}`;
}

export function roundPath(args: { code: string; year: string; round: string }) {
    return `/${args.code}/draw/${args.year}/${args.round}`;
}

export function roundMatchesPath(args: { code: string; year: string; round: string }) {
    return `/${args.code}/draw/${args.year}/${args.round}/matches`;
}

export function strategyPath(args: { code: string }) {
    return `/${args.code}/model/strategy`;
}

export function currentRoundPath(args: { code: string }) {
    return `/${args.code}/currentRound`;
}

export function betRecommendationsPath(args: { code: string; round: string }) {
    return `/recommendations/${args.code}/${args.round}`;
}

export function oddsPath(args: { code: string; round: string; matchKey: string; year?: string | number }) {
    return `/${args.code}/odds/${args.year || 2019}/${args.round}/${args.matchKey}`;
}

export function subscriptionPath(args: { userId: string }) {
    return `/subscriptions/${args.userId};`;
}
