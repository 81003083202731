import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../store';
import { Redirect } from 'react-router';

export function SignOut() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(signOut());
    }, [dispatch]);

    return <Redirect to="/" />;
}
