export enum Actions {
    SyncPath = '@@punters-hq/action/SyncPath',
    SyncPathSuccess = '@@punters-hq/action/SyncPathSuccess',
    SyncPathError = '@@punters-hq/action/SyncPathError',

    StoreSelectedRound = '@@punters-hq/action/StoreSelectedRound',

    WatchAuthStatus = '@@punters-hq/action/WatchAuthStatus',
    UpdateAuthStatus = '@@punters-hq/action/UpdateAuthStatus',
    SignOut = '@@punters-hq/action/SignOut',

    CreateSubscription = '@@punters-hq/action/CreateSubscription',
    CreateSubscriptionError = '@@punters-hq/action/CreateSubscriptionError',
    CreateSubscriptionSuccess = '@@punters-hq/action/CreateSubscriptionSuccess',

    StartLoading = '@@punters-hq/action/StartLoading',
    StopLoading = '@@punters-hq/action/StopLoading',
}
