import { get } from 'lodash';

function loadEnvVariable<T>(key: string, defaultValue?: T): T {
    const value = get(process.env, `REACT_APP_${key}`, defaultValue);

    if (!value) {
        throw Error(`No config value for key ${key}`);
    }

    return value as T;
}

export const API_BASE = loadEnvVariable<string>('API_BASE');
export const FIREBASE_API_KEY = loadEnvVariable<string>('FIREBASE_API_KEY');
export const FIREBASE_APP_ID = loadEnvVariable<string>('FIREBASE_APP_ID');
export const FIREBASE_AUTH_DOMAIN = loadEnvVariable<string>('FIREBASE_AUTH_DOMAIN');
export const FIREBASE_DB_URL = loadEnvVariable<string>('FIREBASE_DB_URL');
export const FIREBASE_MESSAGE_SENDER_ID = loadEnvVariable<string>('FIREBASE_MESSAGE_SENDER_ID');
export const FIREBASE_PROJECT_ID = loadEnvVariable<string>('FIREBASE_PROJECT_ID');
export const FIREBASE_STORAGE_BUCKET = loadEnvVariable<string>('FIREBASE_STORAGE_BUCKET');
export const GOOGLE_TAG_ID = loadEnvVariable<string>('GOOGLE_TAG_ID');
export const STRIPE_KEY = loadEnvVariable<string>('STRIPE_KEY');
export const SENTRY_DSN = loadEnvVariable<string>('SENTRY_DSN');
export const SUPPORTED_CODES = loadEnvVariable<string>('SUPPORTED_CODES', 'nrl,afl');
