import React from 'react';
import Select from 'react-select';
import moment from 'moment';
import { ValueType } from 'react-select/src/types';
import { Typography, Grid } from '@material-ui/core';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
    LineChart,
    Line,
    ResponsiveContainer,
    Legend,
    ReferenceLine,
    Bar,
    ComposedChart,
    YAxis,
    Tooltip,
    Dot,
    ReferenceDot,
    Label,
} from 'recharts';
import { Link } from 'react-router-dom';
import { orderBy, last, round } from 'lodash';

import { YearlyStrategy } from '@punters-hq/common';
import { BetTypeToMarket } from '../components/Recommendations';
import { FirebasePath, InfoPanel } from '../components';
import { strategyPath } from '../utils';
import styles from './Strategy.module.css';
import { AppState } from '../store';

interface StrategyProps {
    code: string;
    year: string;
}

const YEARS = [
    {
        label: '2019',
        value: '2019',
    },
    {
        label: '2018',
        value: '2018',
    },
    {
        label: '2017',
        value: '2017',
    },
    {
        label: '2017-2019',
        value: 'TOTAL',
    },
];

function YearlyResults(props: { results: YearlyStrategy; code: string }) {
    const user = useSelector<AppState, firebase.User | null>(state => state.data.user);
    if (!props.results) {
        return null;
    }

    const records = orderBy(props.results.records, x => moment(x.timestamp).unix());
    const lastRecord = last(records);
    return (
        <>
            <Typography variant="h4" className={styles.return}>
                Return per $1000 capital invested
            </Typography>
            <div className={styles.figures}>
                <Typography variant="h3" className={styles.dollars}>
                    {props.results.pnl.toFixed(0)}
                </Typography>
                <Typography variant="h3" className={styles.percentage}>
                    {((props.results.pnl / 1000) * 100).toFixed(0)}
                </Typography>
            </div>
            <Grid container>
                <Grid item xs={12} className={styles.chart}>
                    <ResponsiveContainer minWidth="100%" minHeight={100} width="100%" height="100%">
                        <LineChart data={records}>
                            <Line
                                name="P+L"
                                dataKey="cumPnlReset"
                                stroke="green"
                                opacity={0.6}
                                strokeWidth={2}
                                dot={false}
                            />
                            <Tooltip formatter={(value: any) => `$${value.toFixed(2)}`} />
                            <ReferenceLine label="$1000" y={0} strokeDasharray="5 5" />
                            <Dot cx={210} cy={lastRecord!.cumPnlReset} r={5} />
                            <ReferenceDot x={records.length - 1} y={lastRecord!.cumPnlReset} r={4} fill="green">
                                <Label value={`$${lastRecord!.cumPnlReset.toFixed(2)}`} position="left" opacity={0.6} />
                            </ReferenceDot>
                        </LineChart>
                    </ResponsiveContainer>
                </Grid>
                {!user && (
                    <Grid item xs={12}>
                        <InfoPanel>
                            <Typography variant="caption">
                                Interested to know more about our service? Please see our{' '}
                                <Link to="/pricing">pricing</Link> page for more info.
                            </Typography>
                        </InfoPanel>
                    </Grid>
                )}
            </Grid>
            <table className={classnames('table striped responsive-table', styles.results)}>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Match</th>
                        <th>Type</th>
                        <th>Odds</th>
                        <th>Result</th>
                        <th>Stake</th>
                        <th>+/-</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {orderBy(records, x => moment(x.timestamp), ['desc']).map(x => {
                        const [homeTeam, awayTeam, date] = x.matchKey.split('-');
                        const winningBet = x.betReturn > 0;
                        return (
                            <tr key={`${x.matchKey}-${x.strategy}`}>
                                <td>{moment(date).format('DD MMM YYYY')}</td>
                                <td>
                                    {homeTeam} ({x.homeScore}) vs. {awayTeam} ({x.awayScore})
                                </td>
                                <td>{BetTypeToMarket[x.betType]}</td>
                                <td>{(x.betReturn + 1).toFixed(2)}</td>
                                <td
                                    className={classnames({
                                        [styles.win]: winningBet,
                                        [styles.loss]: !winningBet,
                                    })}
                                >
                                    {x.betReturn > 0 ? 'WIN' : 'LOSS'}
                                </td>
                                <td>${round(x.resetCapitalBetAmount, 0).toFixed(2)}</td>
                                <td
                                    className={classnames({
                                        [styles.win]: winningBet,
                                        [styles.loss]: !winningBet,
                                    })}
                                >
                                    ${x.resetPnl.toFixed(2)}
                                </td>
                                <td>${x.cumPnlReset.toFixed(2)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}

function TotalResults(props: { results: { [key: string]: YearlyStrategy } }) {
    const years = YEARS.filter(x => x.value !== 'TOTAL')
        .map(x => x.value)
        .sort();
    let runningTotal = 0;
    const cumTotals: { [key: string]: number } = years.reduce((accum: { [key: string]: number }, current: string) => {
        const result = props.results[current];
        runningTotal += last(result.records)!.cumPnlReset;
        accum[current] = runningTotal;
        return accum;
    }, {});
    const data = years.map((x: string) => {
        const result = props.results[x];
        return {
            year: result.year,
            percent: ((result.pnl / 1000) * 100).toFixed(0),
            cumPnl: cumTotals[x],
            pnl: result.pnl,
            noBets: result.records.length,
        };
    });
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <ResponsiveContainer minWidth="100%" minHeight={120} width="100%" height="100%">
                        <ComposedChart data={data}>
                            <Legend />
                            <Bar
                                yAxisId="percent"
                                name="% Return"
                                dataKey="percent"
                                barSize={16}
                                fill="blue"
                                opacity={0.3}
                            />
                            <Bar yAxisId="pnl" name="$ Return" dataKey="pnl" barSize={16} fill="green" opacity={0.4} />
                            <Line
                                yAxisId="cumPnl"
                                name="Cumulative P+L"
                                dataKey="cumPnl"
                                stroke="#ee6e73"
                                strokeWidth={2}
                            />
                            <YAxis scale="linear" name="percent" dataKey="percent" yAxisId="percent" hide={true} />
                            <YAxis scale="linear" name="cumPnl" dataKey="cumPnl" yAxisId="cumPnl" hide={true} />
                            <YAxis scale="linear" name="pnl" dataKey="pnl" yAxisId="pnl" hide={true} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
            <table className={classnames('table striped', styles.results)}>
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>+/-</th>
                        <th>Cum. +/-</th>
                        <th>% Return</th>
                        <th>No. Bets</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(x => {
                        return (
                            <tr key={x.year}>
                                <td>{x.year}</td>
                                <td>${x.pnl.toFixed(2)}</td>
                                <td>${x.cumPnl.toFixed(2)}</td>
                                <td>{x.percent}%</td>
                                <td>{x.noBets}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}

export function Strategy(props: StrategyProps) {
    const dispatch = useDispatch();
    const onSelectChange = (value: string) => {
        dispatch(push(`/${props.code}/strategy/${value}`));
    };

    return (
        <FirebasePath path={strategyPath(props)}>
            {(results: { [key: string]: YearlyStrategy }) => {
                return (
                    <div className={styles.strategy}>
                        <Grid container>
                            <Grid item xs={12} className={styles.select}>
                                <Select
                                    options={YEARS}
                                    value={YEARS.find(x => x.value.toLowerCase() === props.year.toLowerCase())}
                                    onChange={(x: ValueType<any>) => {
                                        onSelectChange(x.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {props.year !== 'TOTAL' ? (
                            <YearlyResults results={results[props.year]} code={props.code} />
                        ) : (
                            <TotalResults results={results} />
                        )}
                    </div>
                );
            }}
        </FirebasePath>
    );
}
