import React from 'react';
import { Typography, makeStyles, Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';

import { Match } from '@punters-hq/common';
import { SignUpHeader } from './SignUpHeader';
import { Menu, Footer, SubscriptionDetails, FirebaseObject, MatchRow } from '../../components';
import { matchPath } from '../../utils';

const useStyles = makeStyles({
    container: {
        padding: '1rem',
        marginTop: '5rem',
    },
    row: {
        marginBottom: '1rem',
    },
    header: {
        fontSize: '1.8rem',
        textAlign: 'center',
    },
    preview: {
        marginTop: '1rem',
        border: `solid 1px ${grey[300]}`,
        borderBottom: 'none',
        paddingTop: '0.5rem',
    },
});

export function Complete() {
    const classes = useStyles();
    return (
        <>
            <SignUpHeader />
            <main>
                <Grid className={classes.container} container>
                    <Grid className={classes.row} item xs={12}>
                        <Typography className={classes.header} variant="h2">
                            Welcome to Punters HQ!
                        </Typography>
                    </Grid>
                    <Grid className={classes.row} item xs={12}>
                        <Typography variant="body1">
                            When viewing upcoming rounds, our bet recommendations will look like this:
                        </Typography>
                        <FirebaseObject
                            path={matchPath({ code: 'nrl', round: '22', year: '2019', matchKey: '2019-22-SYD-WAR' })}
                        >
                            {(match: Match) => {
                                // TODO: afl preview too
                                return (
                                    <div className={classes.preview}>
                                        <MatchRow
                                            code="nrl"
                                            shouldScroll={false}
                                            played={true}
                                            match={match}
                                            onRowClick={() => true}
                                            date={moment(match.date).format('ddd DD MMM h:mm A')}
                                            round={match.round}
                                        />
                                    </div>
                                );
                            }}
                        </FirebaseObject>
                    </Grid>
                    <Grid className={classes.row} item xs={12}>
                        <SubscriptionDetails />
                    </Grid>
                </Grid>
                <Menu />
            </main>
            <Footer />
        </>
    );
}
