import React from 'react';
import classnames from 'classnames';
import { Position, LadderPositions } from '@punters-hq/common';
import { useParams } from 'react-router-dom';
import { chain } from 'lodash';
import { TeamLogo } from '../components/TeamLogo';
import { FirebasePath, SubHeader } from '../components';
import { allPositionsPath } from '../utils';
import styles from './Ladder.module.css';

export const LadderHeader = ({ year, code }: { year: string; code: string }) => {
    return (
        <SubHeader
            header={`${code} Ladder ${year}`}
            navLeftPath={`/${code}/ladder/${Number(year) - 1}`}
            navRightPath={`/${code}/ladder/${Number(year) + 1}`}
        />
    );
};

export const Ladder = () => {
    const params = useParams<{ year: string; code: 'afl' | 'nrl' }>();
    return (
        <FirebasePath path={allPositionsPath(params)}>
            {(value: LadderPositions) => {
                const ladder = chain(value)
                    .values()
                    .sortBy(x => x.position)
                    .value();
                return (
                    <table className="striped">
                        <thead>
                            <tr>
                                <th className="center-align">Pos</th>
                                <th className="center-align">Team</th>
                                <th className="center-align">Points</th>
                                <th className="center-align">Diff</th>
                                <th className="center-align">+ / -</th>
                                <th className="center-align">W / L</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ladder.map((x: Position) => {
                                return (
                                    <tr key={x.team} className={classnames({ [styles['top-8']]: x.position === 7 })}>
                                        <td className="center-align">{x.position + 1}</td>
                                        <td className="center-align">
                                            <TeamLogo code={params.code} team={x.team} />
                                        </td>
                                        <td className="center-align">{x.points}</td>
                                        <td className="center-align">{x.differential}</td>
                                        <td className="center-align">
                                            {x.pointsFor} / {x.pointsAgainst}
                                        </td>
                                        <td className="center-align">
                                            {x.wins} / {x.losses}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                );
            }}
        </FirebasePath>
    );
};
