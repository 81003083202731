import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, syncPathAsync } from '../store';
import { Loading } from './Loading';

interface FirebasePathProps<T> {
    path: string;
    children?: (data: T) => React.ReactElement;
    loading?: () => React.ReactElement;
    defaultValue?: any;
}

export function FirebasePath<T>(props: FirebasePathProps<T>) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(syncPathAsync.request(props.path));
    }, [dispatch, props.path]);

    const data = useSelector<AppState, any>((x: AppState) => {
        return x.data && x.data[props.path];
    });

    if (!data) {
        if (props.defaultValue !== undefined) {
            if (props.children) {
                return props.children(props.defaultValue);
            }
        }
        return !props.loading ? <Loading /> : props.loading();
    }

    if (props.children) {
        return props.children(data);
    }

    return null;
}
