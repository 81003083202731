import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { getTeamsByCode } from '@punters-hq/common';
import { MatchHeader, Team } from './components';
import {
    Match,
    Combined,
    Odds,
    Round,
    TeamResults,
    CurrentRound,
    Strategy,
    LandingPage,
    Stats,
    Teams,
    Ladder,
    LadderHeader,
    Page,
    Pricing,
    TeamResultsHeader,
    SignOut,
    SignIn,
    Account,
} from './pages';
import * as SignUp from './pages/signUp';

interface MatchRouteProps {
    code: string;
    year: string;
    round: string;
    key: string;
}

export function routes() {
    return (
        <Switch>
            <Route path="/:code(nrl|afl|epl)/:year/match/:round/:key" exact>
                {(props: RouteComponentProps<MatchRouteProps>) => {
                    const round = props.match.params.round;
                    return (
                        <Page code={props.match.params.code} year={props.match.params.year}>
                            <>
                                <MatchHeader
                                    matchKey={props.match.params.key}
                                    round={props.match.params.round}
                                    code={props.match.params.code}
                                    year={props.match.params.year}
                                    currentPath={props.location.pathname}
                                />
                                <Match code={props.match.params.code} round={round} matchKey={props.match.params.key} />
                            </>
                        </Page>
                    );
                }}
            </Route>
            <Route path="/:code(nrl|afl|epl)/:year/match/:round/:key/combined" exact>
                {(props: RouteComponentProps<MatchRouteProps>) => {
                    const round = props.match.params.round;
                    return (
                        <Page code={props.match.params.code} year={props.match.params.year}>
                            <>
                                <MatchHeader
                                    matchKey={props.match.params.key}
                                    round={props.match.params.round}
                                    code={props.match.params.code}
                                    year={props.match.params.year}
                                    currentPath={props.location.pathname}
                                />
                                <Combined
                                    code={props.match.params.code}
                                    round={round}
                                    matchKey={props.match.params.key}
                                />
                            </>
                        </Page>
                    );
                }}
            </Route>
            <Route path="/:code(nrl|afl|epl)/:year/match/:round/:key/stats" exact>
                {(props: RouteComponentProps<MatchRouteProps>) => {
                    const round = props.match.params.round;
                    return (
                        <Page code={props.match.params.code} year={props.match.params.year}>
                            <>
                                <MatchHeader
                                    matchKey={props.match.params.key}
                                    round={props.match.params.round}
                                    code={props.match.params.code}
                                    year={props.match.params.year}
                                    currentPath={props.location.pathname}
                                />
                                <Stats code={props.match.params.code} round={round} matchKey={props.match.params.key} />
                            </>
                        </Page>
                    );
                }}
            </Route>
            <Route path="/:code(nrl|afl|epl)/:year/match/:round/:key/odds" exact>
                {(props: RouteComponentProps<MatchRouteProps>) => {
                    return (
                        <Page code={props.match.params.code} year={props.match.params.year}>
                            <>
                                <MatchHeader
                                    matchKey={props.match.params.key}
                                    round={props.match.params.round}
                                    code={props.match.params.code}
                                    year={props.match.params.year}
                                    currentPath={props.location.pathname}
                                />
                                <Odds
                                    code={props.match.params.code}
                                    year={props.match.params.year}
                                    round={props.match.params.round}
                                    matchKey={props.match.params.key}
                                />
                            </>
                        </Page>
                    );
                }}
            </Route>
            <Route path="/:code(nrl|afl|epl)/:year/round/:round" exact>
                {(props: RouteComponentProps<{ code: string; year: string; round: string }>) => {
                    return (
                        <Page code={props.match.params.code} year={props.match.params.year}>
                            <Round {...props.match.params} />
                        </Page>
                    );
                }}
            </Route>
            <Route path="/:code(nrl|afl|epl)/:year/teams" exact>
                {(props: RouteComponentProps<{ code: string; year: string }>) => {
                    const teams = getTeamsByCode(props.match.params.code);
                    return (
                        <Page code={props.match.params.code} year={props.match.params.year}>
                            <Teams teams={teams.teams} code={props.match.params.code} year={props.match.params.year} />
                        </Page>
                    );
                }}
            </Route>
            <Route path="/:code(nrl|afl|epl)/:year/teams/:team" exact>
                {(props: RouteComponentProps<{ code: string; year: string }>) => {
                    return (
                        <Page code={props.match.params.code} year={props.match.params.year}>
                            <Team code={props.match.params.code} />
                        </Page>
                    );
                }}
            </Route>
            <Route path="/:code(nrl|afl|epl)/:year/teams/:team/results/:year" exact>
                {(props: RouteComponentProps<{ code: string; year: string }>) => {
                    return (
                        <Page code={props.match.params.code} year={props.match.params.year}>
                            <>
                                <TeamResultsHeader />
                                <TeamResults />
                            </>
                        </Page>
                    );
                }}
            </Route>
            <Route path="/:code(nrl|afl|epl)/ladder/:year" exact>
                {(props: RouteComponentProps<{ code: string; year: string }>) => {
                    const { code, year } = props.match.params;
                    return (
                        <Page code={props.match.params.code} year={props.match.params.year}>
                            <>
                                <LadderHeader code={code} year={year} />
                                <Ladder />
                            </>
                        </Page>
                    );
                }}
            </Route>
            <Route path="/:code(nrl|afl|epl)/:year" exact>
                {(props: RouteComponentProps<{ code: string; year: string }>) => {
                    return (
                        <Page code={props.match.params.code} year={props.match.params.year}>
                            <CurrentRound {...props.match.params} />
                        </Page>
                    );
                }}
            </Route>
            <Route path="/:code(nrl|afl|epl)/strategy/:year" exact>
                {(props: RouteComponentProps<{ code: string; year: string }>) => {
                    return (
                        <Page code={props.match.params.code} year={props.match.params.year}>
                            <Strategy code={props.match.params.code} year={props.match.params.year} />
                        </Page>
                    );
                }}
            </Route>
            <Route path="/" exact component={LandingPage} />
            <Route path="/sign-in" exact component={SignIn} />
            <Route path="/pricing" exact component={Pricing} />
            <Route path="/sign-up" exact component={SignUp.SignIn} />
            <Route path="/sign-up/:product/subscription" exact component={SignUp.Subscription} />
            <Route path="/sign-up/complete" exact component={SignUp.Complete} />
            <Route path="/account" exact component={Account} />
            <Route path="/sign-out" exact component={SignOut} />
            <Redirect from="*" to="/" />
        </Switch>
    );
}
