import React from 'react';
import * as firebase from 'firebase/app';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { Footer, Menu } from '../../components';
import { auth } from 'firebaseui';
import { app } from '../../firebase';
import { SignUpHeader } from './SignUpHeader';
import { useDispatch } from 'react-redux';
import { updateAuthStatus } from '../../store';
import { ProductType } from '@punters-hq/common';
import { useState } from 'react';

const useStyles = makeStyles({
    container: {
        padding: '1rem',
        marginTop: '5rem',
    },
    center: {
        textAlign: 'center'
    },
    signIn: {
        marginTop: '2rem',
        '& .firebaseui-container.mdl-card': {
            background: 'transparent',
        },
    },
});

export function SignIn() {
    const dispatch = useDispatch();
    const [signedIn, setSignedIn] = useState(false);

    const authConfig: auth.Config = {
        signInFlow: 'redirect',
        signInSuccessUrl: `/sign-up/${ProductType.BothCodesMonthly.toLocaleLowerCase()}/subscription`,
        callbacks: {
            signInFailure: async (error: auth.AuthUIError) => {
                dispatch(updateAuthStatus(null));
            },
            signInSuccessWithAuthResult: (result: any, redirect?: string) => {
                dispatch(updateAuthStatus(result.user));
                setSignedIn(true);
                return true;
            },
        },
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
    };

    const classes = useStyles();
    return (
        <>
            <SignUpHeader />
            <main>
                <Grid className={classes.container} container>
                    <Grid item xs={12}>
                        <Typography className={classes.center} variant="body1">
                            {!signedIn ? 'Please choose your preferred sign-in method' : 'Signed in, please wait...'}
                        </Typography>
                        <StyledFirebaseAuth
                            className={classes.signIn}
                            uiConfig={authConfig}
                            firebaseAuth={app.auth()}
                        />
                    </Grid>
                </Grid>
                <Menu />
            </main>
            <Footer />
        </>
    );
}
