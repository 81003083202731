import React, { useState } from 'react';
import { makeStyles, Grid, Typography, ButtonGroup, Theme, Chip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { StripeProvider, Elements } from 'react-stripe-elements';
import moment from 'moment';

import { Products, ProductType } from '@punters-hq/common';
import { FirebasePath, Menu, Footer, ProductButton, Codes } from '../../components';
import { SignUpHeader } from './SignUpHeader';
import { PaymentDetails } from './PaymentDetails';
import { STRIPE_KEY } from '../../config';

const stripeKey: string = STRIPE_KEY as string;

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: '3rem',
        padding: '1rem',
    },
    item: {
        marginBottom: '1rem',
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(),
            paddingLeft: theme.spacing(),
        },
    },
    row: {
        marginTop: '1rem',
    },
    product: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    subHeader: {
        fontSize: '1rem',
        textTransform: 'uppercase',
        margin: 'auto 0',
        color: grey[700],
    },
    summary: {
        marginTop: '0.5rem',
        lineHeight: '40px',
        '& .MuiChip-root': {
            fontWeight: 'bold',
        },
        '& .codes': {
            textTransform: 'uppercase',
        },
    },
    price: {
        fontWeight: 'bold',
        marginRight: 6,
    },
    subscription: {
        fontWeight: 'bold',
        marginRight: 6,
        marginLeft: 6,
    },
    codes: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginRight: 6,
        marginLeft: 6,
    },
    date: {
        fontWeight: 'bold',
        marginLeft: 6,
    },
}));

export function Subscription() {
    const dispatch = useDispatch();
    const params = useParams<{ product: string }>();
    const selectedProductType = ProductType.parse(params.product);

    const [singleCode, setSingleCode] = useState<string | null>(
        selectedProductType === ProductType.SingleCodeMonthly || selectedProductType === ProductType.SingleCodeSeason
            ? 'nrl'
            : null,
    );

    const onSelectProduct = (type: ProductType) => {
        if (type === ProductType.BothCodesMonthly || type === ProductType.BothCodesSeason) {
            setSingleCode(null);
        } else if (singleCode === null) {
            setSingleCode('nrl');
        }
        dispatch(push(`/sign-up/${type.toLowerCase()}/subscription`));
    };

    const classes = useStyles();
    return (
        <>
            <SignUpHeader />
            <main>
                <FirebasePath path="/products">
                    {(products: Products) => {
                        const selectedProduct = products[selectedProductType];
                        return (
                            <div className={classes.container}>
                                <Grid container className={classes.row}>
                                    <Grid item xs={12}>
                                        <div className={classes.product}>
                                            <Typography className={classes.subHeader} variant="h2">
                                                Single Code
                                            </Typography>
                                            <Codes
                                                selected={{ nrl: singleCode === 'nrl', afl: singleCode === 'afl' }}
                                                onClick={setSingleCode}
                                                disabled={singleCode === null}
                                            />
                                        </div>
                                        <ButtonGroup color="secondary" variant="outlined" size="large" fullWidth>
                                            <ProductButton
                                                product={products[ProductType.SingleCodeMonthly]}
                                                onSelectProduct={onSelectProduct}
                                                selected={selectedProductType === ProductType.SingleCodeMonthly}
                                            />
                                            <ProductButton
                                                product={products[ProductType.SingleCodeSeason]}
                                                onSelectProduct={onSelectProduct}
                                                selected={selectedProductType === ProductType.SingleCodeSeason}
                                            />
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.row}>
                                    <Grid item xs={12}>
                                        <div className={classes.product}>
                                            <Typography className={classes.subHeader} variant="h2">
                                                Both Codes
                                            </Typography>
                                            <Codes
                                                selected={{ nrl: true, afl: true }}
                                                onClick={() => setSingleCode(null)}
                                            />
                                        </div>
                                        <ButtonGroup color="secondary" variant="outlined" size="large" fullWidth>
                                            <ProductButton
                                                product={products[ProductType.BothCodesMonthly]}
                                                onSelectProduct={onSelectProduct}
                                                selected={selectedProductType === ProductType.BothCodesMonthly}
                                            />
                                            <ProductButton
                                                product={products[ProductType.BothCodesSeason]}
                                                onSelectProduct={onSelectProduct}
                                                selected={selectedProductType === ProductType.BothCodesSeason}
                                            />
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.row}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.subHeader} variant="h2">
                                            Summary
                                        </Typography>
                                        <Typography variant="body1" className={classes.summary}>
                                            <Chip
                                                component="span"
                                                className={classes.price}
                                                color="primary"
                                                label={`$${selectedProduct.price.toFixed(2)} + GST`}
                                            />
                                            per
                                            <Chip
                                                component="span"
                                                className={classes.subscription}
                                                color="primary"
                                                label={selectedProduct.subscription.toString().toLowerCase()}
                                            />
                                            for
                                            <Chip
                                                component="span"
                                                className={classes.codes}
                                                color="primary"
                                                label={singleCode || 'nrl + afl'}
                                            />
                                            subscription to be charged on
                                            <Chip
                                                component="span"
                                                className={classes.date}
                                                color="primary"
                                                label={moment()
                                                    .add(14, 'days')
                                                    .format('DD MMM YYYY')}
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <StripeProvider apiKey={stripeKey}>
                                    <Elements>
                                        <PaymentDetails
                                            product={products[selectedProductType]}
                                            codes={singleCode ? [singleCode] : ['afl', 'nrl']}
                                        />
                                    </Elements>
                                </StripeProvider>
                            </div>
                        );
                    }}
                </FirebasePath>
                <Menu />
            </main>
            <Footer />
        </>
    );
}
