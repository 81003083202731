import React from 'react';
import { InfoOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

interface InfoPanelProps {
    children: React.ReactNode;
}

const useStyles = makeStyles({
    info: {
        borderRadius: 2,
        border: `solid 1px ${green[900]}`,
        backgroundColor: green[100],
        display: 'flex',
        padding: 6,
        margin: 10,
        marginTop: 0,
        '& svg': {
            margin: 'auto',
            marginRight: 10,
        },
    },
});

export function InfoPanel(props: InfoPanelProps) {
    const classes = useStyles();
    return (
        <div className={classes.info}>
            <InfoOutlined />
            {props.children}
        </div>
    );
}
